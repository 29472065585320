import { Container, Stack } from "@mui/material";
import NutritionChart from "./NutritionChart";
import React from "react";
import axios, { AxiosResponse } from "axios";
import LoadingWidget from "../common/LoadingWidget";

interface NutritionInfo {
  aisleName: string;
  calories: number;
  pFat: number;
  pCarbs: number;
  pProtein: number;
}

class NutritionChartVisualization extends React.Component<
  {},
  { nutritionInfo: NutritionInfo[] }
> {
  constructor(props: any) {
    super(props);
    this.state = { nutritionInfo: [] };
  }

  componentDidMount() {
    this.loadNutritionInfo();
  }

  async loadNutritionInfo() {
    const aisles = [
      //'Alcoholic Beverages',
      "Bakery/Bread",
      //'Baking',
      //'Beverages',
      //'Canned and Jarred',
      "Cereal",
      "Milk, Eggs, Other Dairy",
      "Produce",
      "Seafood",
      "Cheese",
      //'Condiments',
      //'Gourmet',
      //'Health Foods',
      //'Oil, Vinegar, Salad Dressing',
      //'Spices and Seasonings',
      //'Frozen',
      //'Gluten Free',
      "Meat",
      //'Nut butters, Jams, and Honey',
      "Nuts",
      //'Savory Snacks',
      "Pasta and Rice",
      //'Refrigerated',
      "Sweet Snacks",
    ];

    let nutritionInfos: NutritionInfo[] = [];
    await Promise.all(
      aisles.map(async (aisleName) => {
        let response: AxiosResponse<any, any> = await axios.get(
          `https://api.seastainable.me/ingredients?page=1&instancesPerPage=500&aisle=${aisleName}`
        );
        let count: number = response.data["count"];

        let avgCalPerGram = 0;
        let avgFat = 0;
        let avgCarbs = 0;
        let avgProtein = 0;
        let avgCount = 0;
        for (let i = 0; i < count; i++) {
          let nutrients = JSON.parse(response.data["data"][i]["nutrition"])[
            "nutrients"
          ];
          let calories: number = 0;
          nutrients.forEach(async function (element: any) {
            if (element["name"] === "Calories")
              calories = parseInt(element["amount"], 10);
          });

          let match = response.data["data"][i]["weightPerServing"].match(/\d+/);
          let gramsPerServing: number = parseInt(match[0], 10);

          if (calories <= 0 || gramsPerServing <= 0) {
            continue;
          }

          let pFat = parseFloat(response.data["data"][i]["percentFat"]);
          let pCarbs = parseFloat(response.data["data"][i]["percentCarbs"]);
          let pProtein = parseFloat(response.data["data"][i]["percentProtein"]);

          avgCalPerGram += calories / gramsPerServing;
          avgFat += pFat;
          avgCarbs += pCarbs;
          avgProtein += pProtein;
          avgCount++;
        }
        avgCalPerGram /= avgCount;
        avgFat /= avgCount;
        avgCarbs /= avgCount;
        avgProtein /= avgCount;

        let nInfo: NutritionInfo = {
          aisleName: aisleName,
          calories: Math.round(avgCalPerGram * 100),
          pFat: avgFat,
          pCarbs: avgCarbs,
          pProtein: avgProtein,
        };
        nutritionInfos.push(nInfo);
      })
    );

    this.setState({
      nutritionInfo: nutritionInfos,
    });
  }

  render() {
    if (this.state.nutritionInfo.length === 0) {
      return <LoadingWidget height="300px" />;
    }

    return (
      <Container>
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {this.state.nutritionInfo.map((ni: NutritionInfo) => (
            <NutritionChart
              key={ni.aisleName}
              label={ni.aisleName}
              avgCal={ni.calories}
              avgCarb={ni.pCarbs}
              avgFat={ni.pFat}
              avgProtein={ni.pProtein}
              type="small"
            />
          ))}
        </Stack>
      </Container>
    );
  }
}

export default NutritionChartVisualization;
