import { stateOptions } from "../common/states";
import { ExactFilter, RangeFilter, SortOption } from "../toolbar/ModelToolbar";
import { industries } from "./industries";
import sectors from "./sectors";

const CompanyExactFilters: ExactFilter[] = [
  {
    label: "State Filter",
    field: "state",
    options: stateOptions,
  },
  {
    label: "City Filter",
    field: "cityName",
  },
  {
    label: "Sector Filter",
    field: "sector",
    options: sectors.map((sector) => ({ label: sector, value: sector })),
  },
  {
    label: "Industry Filter",
    field: "industries",
    options: industries.map((sector) => ({ label: sector, value: sector })),
  },
];

const CompanyRangeFilters: RangeFilter[] = [
  {
    label: "Annual Revenue",
    field: "annualRevenue",
    min: 1_000_000_000,
    max: 500_000_000_000,
    nonlinear: true,
    compactNumbers: true,
    // step: 1_000_000,
    marks: [
      {
        label: "<1B",
        value: 1_000_000_000,
      },
      {
        label: "10B",
        value: 10_000_000_000,
      },
      {
        label: "100B",
        value: 100_000_000_000,
      },
      {
        label: ">500B",
        value: 500_000_000_000,
      },
    ],
  },
  {
    label: "Employees",
    field: "employees",
    min: 100,
    max: 100_000,
    nonlinear: true,
    compactNumbers: true,
    marks: [
      {
        label: "<100",
        value: 100,
      },
      {
        label: "1K",
        value: 1_000,
      },
      {
        label: "10K",
        value: 10_000,
      },
      {
        label: ">100K",
        value: 100_000,
      },
    ],
  },
];

const CompanySortOptions: SortOption[] = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Employees",
    field: "employees",
  },
  // {
  //     label: "Sector (A-Z)",
  //     field: "sector",
  //     ascending: true
  // },
  // {
  //     label: "Sector (Z-A)",
  //     field: "sector",
  //     ascending: false
  // },
  // {
  //     label: "Global Rank (Asc)",
  //     field: "alexaGlobalRank",
  //     ascending: true
  // },
  // {
  //     label: "Global Rank (Desc)",
  //     field: "alexaGlobalRank",
  //     ascending: false
  // },
  // {
  //     label: "US Rank (Asc)",
  //     field: "alexaUsRank",
  //     ascending: true
  // },
  // {
  //     label: "US Rank (Desc)",
  //     field: "alexaUsRank",
  //     ascending: false
  // },
  {
    label: "Annual Revenue",
    field: "annualRevenue",
  },
  // {
  //     label: "Year Founded (Asc)",
  //     field: "founded",
  //     ascending: true
  // },
  // {
  //     label: "Year Founded (Desc)",
  //     field: "founded",
  //     ascending: false
  // },
];

export { CompanyExactFilters, CompanyRangeFilters, CompanySortOptions };
