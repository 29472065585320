import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../globals";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  Tooltip,
} from "recharts";
import { Box, Typography } from "@mui/material";
import LoadingWidget from "../common/LoadingWidget";
import College from "../college/college";

function CollegeSATVisualization(props: any) {
  let [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (data.length === 0) {
      const getData = async () => {
        let collegeResponse: AxiosResponse<any, any> = await axios.get(
          `${API_URL}/colleges?pageSize=2000`
        );
        let colleges = collegeResponse.data["data"]["colleges"];
        let data = process_data(colleges);
        setData(data);
      };
      getData().then(() => console.log("loaded"));
    }
  }, [data]);

  let visualization = <LoadingWidget height="300px" />;
  if (data.length > 0) visualization = scatter_chart(data);
  return (
    <Box display="flex" justifyContent="center">
      {visualization}
    </Box>
  );
}

const process_data = (colleges: College[]) => {
  var ret: any[] = [];
  for (const college of colleges) {
    ret.push({
      x: college.satAvg,
      y: 100 * college.admissionRate,
      name: college.name,
    });
  }
  return ret;
};

const renderTooltip = (props: any) => {
  const { active, payload } = props;

  if (active && payload !== null && payload.length > 0) {
    const data = payload[0].payload;

    return (
      <Box
        sx={{
          backgroundColor: "white",
          margin: 0,
          borderRadius: "16px",
          padding: "16px",
          border: "1px solid black"
        }}
      >
        <Typography>{"College: " + data.name}</Typography>
        <Typography>
          {"Average SAT Score: " + data.x.toLocaleString()}
        </Typography>
        <Typography>{"Admission Rate: " + data.y.toFixed(2) + "%"}</Typography>
      </Box>
    );
  }

  return null;
};

const scatter_chart = (data: any[]) => {
  return (
    <ScatterChart
      width={800}
      height={500}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 30,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey="x" type="number" domain={[700, 1600]}>
        <Label
          value="Average SAT Score"
          offset={-16}
          position="insideBottom"
          style={{ textAnchor: "middle" }}
        />
      </XAxis>
      <YAxis dataKey="y" type="number">
        <Label
          angle={-90}
          value="Admission Rate"
          position="insideLeft"
          style={{ textAnchor: "middle" }}
        />
      </YAxis>
      <Scatter data={data} fill="blue" />
      <Tooltip content={renderTooltip} />
    </ScatterChart>
  );
};
export default CollegeSATVisualization;
