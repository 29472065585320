import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./components/about/About";
import NavigationBar from "./components/navigationBar";
import CollegeModelPage from "./components/college/CollegeModelPage";
import CollegeInstancePage from "./components/college/CollegeInstancePage";
import CompanyModelPage from "./components/company/CompanyModelPage";
import CompanyInstancePage from "./components/company/CompanyInstancePage";
import CityModelPage from "./components/city/CityModelPage";
import CityInstancePage from "./components/city/CityInstancePage";
import SearchPage from "./components/search/SearchPage";
import VisualizationPage from "./components/visualization/VisualizationPage";
import ProviderVisualizationsPage from "./components/visualization/ProviderVisualizationsPage";
import HomePage from "./components/home/HomePage";

function App() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/colleges" element={<CollegeModelPage />} />
        <Route path="/colleges/:collegeId" element={<CollegeInstancePage />} />
        <Route path="/cities" element={<CityModelPage />} />
        <Route path="/cities/:cityId" element={<CityInstancePage />} />
        <Route path="/companies" element={<CompanyModelPage />} />
        <Route path="/companies/:companyId" element={<CompanyInstancePage />} />
        <Route path="/visualizations" element={<VisualizationPage />} />
        <Route
          path="/providervisualizations"
          element={<ProviderVisualizationsPage />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
