import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CollegeModelCard from "./CollegeModelCard";
import axios, { AxiosResponse } from "axios";
import { Container, Stack, Typography } from "@mui/material";
import { ModelToolbar } from "../toolbar/ModelToolbar";
import LoadingWidget from "../common/LoadingWidget";
import { API_URL } from "../globals";
import {
  CollegeExactFilters,
  CollegeRangeFilters,
  CollegeSortOptions,
} from "./collegeFilters";
import CardPagination from "../common/CardPagination";
import College from "./college";

function CollegeModelPage(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  let [colleges, setColleges] = useState<College[] | null>(null);
  let [total, setTotal] = useState<number>(0);
  let [pageCount, setPageCount] = useState<number>(0);
  let [page, setPage] = useState<number>(0);
  let [pageSize, setPageSize] = useState<number>(0);
  let [focusFields, setFocusFields] = useState<Set<string>>(new Set<string>());

  useEffect(() => {
    const fetchData = async () => {
      setColleges(null);
      setTotal(0);
      let requiredFields: string = "thumbnailUrl,description";
      let newParams: URLSearchParams = new URLSearchParams(
        searchParams.toString()
      );
      newParams.set("required", requiredFields);
      console.log(newParams);
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/colleges?` + newParams
      );
      let data = response.data["data"];
      setColleges(data["colleges"]);
      setTotal(data["total"]);
      setPage(data["page"]);
      setPageSize(data["pageSize"]);
      setPageCount(Math.ceil(data["total"] / data["pageSize"]));
    };
    fetchData();
  }, [searchParams]);

  const updateQuery = (param: string, value: string) => {
    let newParams: URLSearchParams = new URLSearchParams(
      searchParams.toString()
    );
    newParams.set(param, value);
    setSearchParams(newParams);
  };

  return (
    <Container
      className="page-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: (colleges ?? []).length === 0 ? "100%" : "none",
      }}
    >
      <Typography
        gutterBottom
        className="modelTitle"
        variant="h2"
        sx={{ textAlign: "center" }}
      >
        Colleges
      </Typography>
      <div>
        <ModelToolbar
          exactFilters={CollegeExactFilters}
          rangeFilters={CollegeRangeFilters}
          sortOptions={CollegeSortOptions}
          defaultSortOptionIndex={1}
          sortAscending={false}
          onFocusFieldsUpdate={setFocusFields}
        />
      </div>
      {colleges === null && <LoadingWidget height="none" grow={true} />}
      {colleges !== null && (
        <Stack direction="row" flexWrap="wrap">
          {colleges.map((c) => (
            <CollegeModelCard
              key={c.id}
              college={c}
              query={searchParams.get("query") ?? undefined}
              showEnrollment={true}
              showAdmissionRate={focusFields.has("admissionRate")}
              showAvgSAT={focusFields.has("satAvg")}
              showSatMidMath={focusFields.has("satMidMath")}
              showSatMidRead={focusFields.has("satMidRead")}
              showAvgCost={focusFields.has("avgCost")}
              onLevelClick={(value) => {
                updateQuery("levelFilter", value);
              }}
              onTypeControlClick={(value) => {
                updateQuery("typeControlFilter", value);
              }}
            />
          ))}
        </Stack>
      )}
      <CardPagination
        page={page}
        pageSize={pageSize}
        pageCount={pageCount}
        total={total}
        onPageChange={(page) => updateQuery("page", page.toString())}
        onPageSizeChange={(pageSize) =>
          updateQuery("pageSize", pageSize.toString())
        }
      />
    </Container>
  );
}

export default CollegeModelPage;
