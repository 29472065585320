import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./companyStyle.css";
import ModelCard from "../common/ModelCard";
import Highlighter from "react-highlight-words";
import { Chip, Stack } from "@mui/material";
import Company from "./company";

interface CompanyModelCardProps {
  company: Company;
  query?: string;
}

class CompanyModelCard extends React.Component<CompanyModelCardProps, {}> {
  render() {
    let company = this.props.company;
    return (
      <ModelCard
        height="250px"
        imageMinHeight="50px"
        fitImage={true}
        image={company.logoURL}
        href={`/companies/${company.id}`}
      >
        <CardContent>
          <Typography variant="h5" component="div">
            <Highlighter
              highlightClassName="highlighter"
              searchWords={this.props.query?.split(" ") ?? []}
              autoEscape={true}
              textToHighlight={company.name}
            />
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            <Highlighter
              highlightClassName="highlighter"
              searchWords={this.props.query?.split(" ") ?? []}
              autoEscape={true}
              textToHighlight={company.cityName + ", " + company.state}
            />
          </Typography>
          {company.distance !== undefined && (
            <Typography variant="body2" color="text.secondary">
              {company.distance + " miles away"}
            </Typography>
          )}
          <Stack direction="row" spacing={1} sx={{ marginTop: "8px" }}>
            <Chip
              label={company.public ? "Public" : "Private"}
              size="small"
              color={company.public ? "success" : "warning"}
            />
          </Stack>
        </CardContent>
      </ModelCard>
    );
  }
}

export default CompanyModelCard;
