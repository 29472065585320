import React from "react";
import "./collegeStyle.css";
import { Box, Tooltip } from "@mui/material";
import { RadialBarChart, RadialBar, PolarAngleAxis, Text } from "recharts";

interface SatScoreChartProps {
  label: string;
  score: number;
  minScore: number;
  maxScore: number;
  type: string;
}

interface ChartStyle {
  width: number;
  height: number;
  barFill: string;
  radius: number;
  barSize: number;
  centerX: number | string;
  centerY: number | string;
  valueFontSize: string;
  labelFontSize: string;
}

class SatScoreChart extends React.Component<SatScoreChartProps, {}> {
  render() {
    let label = this.props.label;
    let score = this.props.score;
    let minScore = this.props.minScore;
    let maxScore = this.props.maxScore;

    const styleOptions = new Map<string, ChartStyle>([
      [
        "small",
        {
          width: 300,
          height: 150,
          barFill: "rgb(85,114,239)",
          radius: 120,
          barSize: 30,
          centerX: "50%",
          centerY: 140,
          valueFontSize: "32px",
          labelFontSize: "16px",
        },
      ],
    ]);

    const cs: ChartStyle =
      styleOptions.get(this.props.type) ?? styleOptions.get("small")!;

    const data = [
      {
        uv: score,
        fill: cs.barFill,
      },
    ];

    let CustomLabel = (props: any) => {
      const { cx, cy } = props.viewBox;
      return (
        <>
          <Text
            x={cx}
            y={cy - 55}
            fill="rgba(0, 0, 0, 0.87)"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={cs.valueFontSize}
          >
            {props.value1}
          </Text>
          <Text
            x={cx}
            y={cy - 25}
            fill="rgba(0, 0, 0, 0.54)"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={cs.labelFontSize}
          >
            {props.value2}
          </Text>
        </>
      );
    };

    return (
      <Tooltip
        title={"See schools with similar " + label + " scores"}
        followCursor={true}
        enterDelay={500}
      >
        <Box>
          <RadialBarChart
            width={cs.width}
            height={cs.height}
            cx={cs.centerX}
            cy={cs.centerY}
            startAngle={180}
            endAngle={0}
            innerRadius={cs.radius}
            outerRadius={cs.radius}
            barSize={cs.barSize}
            data={data}
          >
            <PolarAngleAxis
              type="number"
              domain={[minScore, maxScore]}
              dataKey={"uv"}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar
              background
              dataKey="uv"
              cornerRadius={cs.barSize}
              label={<CustomLabel value1={data[0].uv} value2={label} />}
            />
          </RadialBarChart>
        </Box>
      </Tooltip>
    );
  }
}

export default SatScoreChart;
