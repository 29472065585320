import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import React from "react";

interface StatCardProps {
  title?: string;
  elevation?: number;
}

function StatCard(props: React.PropsWithChildren<StatCardProps>) {
  return (
    <Card className="instanceCard card" elevation={props.elevation}>
      <CardContent>
        {props.title !== undefined && (
          <Typography gutterBottom variant="h4" component="div" align="center">
            {props.title}
          </Typography>
        )}
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {props.children}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default StatCard;
