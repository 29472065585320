import { stateOptions } from "../common/states";
import { ExactFilter, RangeFilter, SortOption } from "../toolbar/ModelToolbar";
import knownFor from "./knownFor";

const CityExactFilters: ExactFilter[] = [
  {
    label: "State Filter",
    field: "state",
    options: stateOptions,
  },
  {
    label: "Known For Filter",
    field: "knownFor",
    options: knownFor.map((kf) => {
      return { label: kf, value: kf };
    }),
  },
];

const CityRangeFilters: RangeFilter[] = [
  {
    label: "Population",
    field: "population",
    min: 10_000,
    max: 8_000_000,
    nonlinear: true,
    compactNumbers: true,
    marks: [
      {
        label: "<10K",
        value: 10_000,
      },
      {
        label: "25K",
        value: 25_000,
      },
      {
        label: "100K",
        value: 100_000,
      },
      {
        label: "500K",
        value: 500_000,
      },
      {
        label: "1M",
        value: 1_000_000,
      },
      {
        label: "2M",
        value: 2_000_000,
      },
      {
        label: "4M",
        value: 4_000_000,
      },
      {
        label: ">8M",
        value: 8_000_000,
      },
    ],
  },
  {
    label: "Elevation",
    field: "elevation",
    min: 0,
    max: 8000,
    step: 1,
    marks: [
      {
        label: "0K",
        value: 0,
      },
      {
        label: "2K",
        value: 2000,
      },
      {
        label: "4K",
        value: 4000,
      },
      {
        label: "6K",
        value: 6000,
      },
      {
        label: "8K",
        value: 8000,
      },
    ],
    compactNumbers: true,
  },
  {
    label: "Safety Score",
    field: "safety",
    min: 1,
    max: 5,
    step: 1,
  },
  {
    label: "Budget Score",
    field: "budget",
    min: 1,
    max: 8,
    step: 1,
  },
];

const CitySortOptions: SortOption[] = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Population",
    field: "population",
  },
  {
    label: "Elevation",
    field: "elevation",
  },
  {
    label: "Area (Asc)",
    field: "area",
  },
  {
    label: "Population Density",
    field: "populationDensity",
  },
  {
    label: "Covid Score",
    field: "covidScore",
  },
  {
    label: "Average Rating",
    field: "averageRating",
  },
];

export { CityExactFilters, CityRangeFilters, CitySortOptions };
