import { Search } from "@material-ui/icons";
import {
  Clear,
  ArrowUpward as AscendingIcon,
  ArrowDownward as DescendingIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ChipFilterField, Option } from "./ChipFilterField";
import { Mark, SliderFilterField } from "./SliderFilterField";

interface ModelToolbarProps {
  exactFilters: ExactFilter[];
  rangeFilters: RangeFilter[];
  sortOptions: SortOption[];
  defaultSortOptionIndex?: number;
  sortAscending: boolean;
  onFocusFieldsUpdate?: (focusFields: Set<string>) => void;
}

interface ExactFilter {
  label: string;
  field: string;
  options?: Option[];
}

interface RangeFilter {
  min: number;
  max: number;
  step?: number;
  field: string;
  label: string;
  marks?: Mark[];
  nonlinear?: boolean;
  compactNumbers?: boolean;
}

interface SortOption {
  label: string;
  field: string;
}

function ModelToolbar(props: ModelToolbarProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sortOption, setSortOption] = React.useState<SortOption>(
    props.sortOptions[props.defaultSortOptionIndex ?? 0]
  );
  const [sortAscending, setSortAscending] = React.useState<boolean>(
    props.sortAscending
  );

  const handleSortChange = (option: SortOption) => {
    let newParams = searchParams;
    newParams.set("sortField", option.field);
    newParams.delete("page");
    setSearchParams(newParams);
  };

  const handleSortDirectionChange = (sortAscending: boolean) => {
    let newParams = searchParams;
    newParams.set("ascending", sortAscending ? "true" : "false");
    newParams.delete("page");
    setSearchParams(newParams);
  };

  const submitSearch = (query: string) => {
    let newParams = searchParams;
    if (query.length === 0) {
      newParams.delete("query");
    } else {
      newParams.set("query", query);
    }
    newParams.delete("page");
    setSearchParams(newParams);
  };

  const optionToValue = (option: SortOption): string => {
    return option.field;
  };

  const valueToOption = (value: string): SortOption => {
    let option: SortOption =
      props.sortOptions.find((o) => o.field === value) ??
      props.sortOptions[props.defaultSortOptionIndex ?? 0];
    return option;
  };

  const clearFilters = () => {
    let newParams = searchParams;
    for (let exactFilter of props.exactFilters) {
      newParams.delete(exactFilter.field + "Filter");
    }
    for (let rangeFilter of props.rangeFilters) {
      newParams.delete(rangeFilter.field + "Min");
      newParams.delete(rangeFilter.field + "Max");
    }
    newParams.delete("query");
    newParams.delete("sortField");
    newParams.delete("ascending");
    newParams.delete("page");
    setSearchParams(newParams);
  };

  const getChipFilterFieldValue = (field: string) => {
    let param: string = searchParams.get(field + "Filter") ?? "";
    let paramValues: string[] = param === "" ? [] : param.split(",");
    return paramValues;
  };

  let onFocusFieldsUpdate = props.onFocusFieldsUpdate;
  let rangeFilters = props.rangeFilters;
  let exactFilters = props.exactFilters;
  let sortOptions = props.sortOptions;
  let defaultSortOptionIndex = props.defaultSortOptionIndex;

  useEffect(() => {
    setSearchQuery(searchParams.get("query") ?? "");
    let sortAscending: boolean =
      searchParams.get("ascending") === "true" ? true : false;
    setSortAscending(sortAscending);
    let searchParamsString = searchParams.toString();
    if (
      searchParamsString.includes("Max") ||
      searchParamsString.includes("Min") ||
      searchParamsString.includes("Filter")
    ) {
      setShowFilters(true);
    }

    let sortFilter: string | null = searchParams.get("sortField");
    if (sortFilter !== null) {
      setSortOption(
        sortOptions.find((o) => o.field === sortFilter) ??
          sortOptions[defaultSortOptionIndex ?? 0]
      );
    } else {
      setSortOption(sortOptions[defaultSortOptionIndex ?? 0]);
    }
  }, [searchParams, sortOptions, defaultSortOptionIndex]);

  useEffect(() => {
    let newFocusFields = new Set<string>();
    for (let exactFilter of exactFilters) {
      if (searchParams.has(exactFilter.field + "Filter")) {
        newFocusFields.add(exactFilter.field);
      }
    }
    for (let rangeFilter of rangeFilters) {
      if (
        searchParams.has(rangeFilter.field + "Min") ||
        searchParams.has(rangeFilter.field + "Max")
      ) {
        newFocusFields.add(rangeFilter.field);
      }
    }
    if (searchParams.has("sortField")) {
      newFocusFields.add(searchParams.get("sortField")!);
    }
    if (onFocusFieldsUpdate !== undefined) onFocusFieldsUpdate(newFocusFields);
  }, [
    searchParams,
    exactFilters,
    rangeFilters,
    sortOptions,
    onFocusFieldsUpdate,
  ]);

  return (
    <Card className="card" sx={{ margin: "8px", padding: "8px" }}>
      <CardContent
        sx={{
          gap: "8px",
          paddingBottom: "16px !important",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1}>
            <TextField
              id="search-field"
              label="Search"
              value={searchQuery}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  submitSearch(searchQuery);
                }
              }}
              onChange={(event) => submitSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment:
                  searchParams.get("query") === null ? undefined : (
                    <InputAdornment position="start">
                      <IconButton onClick={() => submitSearch("")}>
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                sx: {
                  borderRadius: "8px",
                  backgroundColor: "grey",
                },
              }}
              sx={{
                borderRadius: "8px",
                // backgroundColor: 'grey',
                flexGrow: 1,
                display: "flex",
              }}
              // notched={false}
              variant="outlined"
            />
            {props.sortOptions.length > 0 && (
              <TextField
                id="filter-field"
                select
                label="Sort"
                value={optionToValue(sortOption)}
                onChange={(event) =>
                  handleSortChange(valueToOption(event.target.value))
                }
                InputProps={{
                  sx: {
                    borderRadius: "8px",
                    backgroundColor: "grey",
                    flexGrow: 1,
                    minWidth: "150px",
                    display: "flex",
                  },
                }}
              >
                {props.sortOptions.map((option) => (
                  <MenuItem
                    key={optionToValue(option)}
                    value={optionToValue(option)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {props.sortOptions.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title={sortAscending ? "Ascending" : "Descending"}>
                  <IconButton
                    onClick={() => handleSortDirectionChange(!sortAscending)}
                  >
                    {sortAscending && <AscendingIcon />}
                    {!sortAscending && <DescendingIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button onClick={() => setShowFilters(!showFilters)}>
                {showFilters ? "HIDE FILTERS" : "SHOW FILTERS"}
              </Button>
            </Box>
          </Stack>
          {showFilters && (
            <Stack direction="row" gap={2} flexWrap="wrap">
              {props.exactFilters.map((f) => (
                <Box sx={{ flexGrow: 1 }}>
                  <ChipFilterField
                    value={getChipFilterFieldValue(f.field)}
                    key={f.field}
                    label={f.label}
                    field={f.field}
                    options={f.options}
                    onChange={(value: string[]) => {
                      let newParams = searchParams;
                      if (value.length === 0) {
                        newParams.delete(f.field + "Filter");
                      } else {
                        newParams.set(f.field + "Filter", value.join(","));
                      }
                      newParams.delete("page");
                      setSearchParams(newParams);
                    }}
                  />
                </Box>
              ))}
            </Stack>
          )}
          {showFilters && (
            <Stack direction="row" gap={1} flexWrap="wrap">
              {props.rangeFilters.map((f) => (
                <Box sx={{ flexBasis: "300px", flexGrow: 1 }}>
                  <SliderFilterField
                    value={[
                      parseInt(
                        searchParams.get(f.field + "Min") ?? f.min.toString()
                      ),
                      parseInt(
                        searchParams.get(f.field + "Max") ?? f.max.toString()
                      ),
                    ]}
                    key={f.field}
                    label={f.label}
                    min={f.min}
                    max={f.max}
                    step={f.step}
                    field={f.field}
                    marks={f.marks}
                    nonlinear={f.nonlinear}
                    autoMark={true}
                    compactNumbers={f.compactNumbers}
                    onChange={(value) => {
                      console.log("change: " + f.field);
                      let newParams = searchParams;
                      newParams.set(f.field + "Min", value[0].toString());
                      newParams.set(f.field + "Max", value[1].toString());
                      if (value[0] === f.min) {
                        newParams.delete(f.field + "Min");
                      }
                      if (value[1] === f.max) {
                        newParams.delete(f.field + "Max");
                      }
                      newParams.delete("page");
                      setSearchParams(newParams);
                    }}
                  />
                </Box>
              ))}
            </Stack>
          )}
          {showFilters && (
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <Button onClick={() => clearFilters()}>Clear Filters</Button>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export { ModelToolbar, ExactFilter, RangeFilter, SortOption };
