import React from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Stack,
  Typography,
  Rating,
  Box,
} from "@mui/material";
import CompanyModelCard from "../company/CompanyModelCard";
import CollegeModelCard from "../college/CollegeModelCard";
import LoadingWidget from "../common/LoadingWidget";
import { Event as EventsIcon } from "@material-ui/icons";
import {
  PeopleAlt as PeopleAltIcon,
  Apartment as ApartmentIcon,
  Map as MapIcon,
  Landscape as LandscapeIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { RadialBarChart, RadialBar, PolarAngleAxis, Text } from "recharts";
import StatCard from "../common/StatCard";
import SimpleStat from "../common/SimpleStat";
import City from "./city";
import College from "../college/college";
import Company from "../company/company";
import { API_URL } from "../globals";

function CityInstancePage() {
  let id: string = useParams().cityId ?? "1";
  let navigate: NavigateFunction = useNavigate();
  return <CityInstancePageCC id={id} navigate={navigate} />;
}

const safetyText = ["Very Unsafe", "Unsafe", "Medium", "Safe", "Very Safe"];
const budgetText = [
  "Very Low",
  "Low",
  "Medium Low",
  "Medium",
  "Medium High",
  "High",
  "Very High",
  "Extremely High",
];
const ratingText = ["Very Poor", "Poor", "Fair", "Good", "Excellent"];

interface CityInstancePageProps {
  id: string;
  navigate: NavigateFunction;
}

interface CityInstancePageState {
  city: City | null;
  nearbyCompanies: Company[] | null;
  nearbyColleges: College[] | null;
  showMore: boolean;
}

class CityInstancePageCC extends React.Component<
  CityInstancePageProps,
  CityInstancePageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      city: null,
      nearbyCompanies: null,
      nearbyColleges: null,
      showMore: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let response: AxiosResponse<any, any> = await axios.get(
      API_URL + `/cities/${this.props.id}`
    );
    let city: City | null = response.data["data"]["city"];
    if (city == null) {
      return;
    }

    let collegesResponse: AxiosResponse<any, any> = await axios.get(
      API_URL +
        `/colleges?cityNameFilter=${city.name}&stateFilter=${city.state}&pageSize=6&required=thumbnailUrl,description`
    );

    let companiesResponse: AxiosResponse<any, any> = await axios.get(
      API_URL +
        `/companies?cityNameFilter=${city.name}&stateFilter=${city.state}&pageSize=6`
    );

    let nearbyColleges: College[] | null =
      collegesResponse.data["data"]["colleges"];
    let nearbyCompanies: Company[] | null =
      companiesResponse.data["data"]["companies"];

    this.setState({
      city: city,
      nearbyColleges: nearbyColleges,
      nearbyCompanies: nearbyCompanies,
      showMore: false,
    });
  }

  getDescription() {
    let description: string = this.state.city!.description;
    if (description.length <= 575) {
      return (
        <Typography variant="body1" color="text.primary">
          {description}
        </Typography>
      );
    }
    description = this.state.showMore
      ? description
      : this.getShortDescription();
    let more: boolean = this.state.showMore;
    return (
      <>
        <Typography variant="body1" color="text.primary">
          {description}
        </Typography>
        <Button
          sx={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          variant="text"
          size="small"
          onClick={() => {
            this.setState({ showMore: !more });
          }}
        >
          {this.state.showMore ? "Show Less" : "Read More"}
        </Button>
      </>
    );
  }

  getShortDescription() {
    if (this.state.city === null) {
      return "";
    } else if (this.state.city.description.length < 600) {
      return this.state.city.description;
    } else {
      let description: string = this.state.city.description;
      let after: string = description.substring(600);
      let index: number = after.indexOf(". ");
      if (index === -1) {
        return description;
      } else {
        return description.substring(0, 600 + index + 1);
      }
    }
  }

  render() {
    if (this.state.city == null) return <LoadingWidget grow={true} />;

    let city: City = this.state.city!;
    console.log(this.state.nearbyColleges);
    let showNearbyColleges =
      this.state.nearbyColleges !== null &&
      this.state.nearbyColleges !== undefined &&
      this.state.nearbyColleges.length > 0;

    let showNearbyCompanies =
      this.state.nearbyCompanies !== null &&
      this.state.nearbyCompanies !== undefined &&
      this.state.nearbyCompanies.length > 0;

    let loadingNearby =
      this.state.nearbyColleges === null || this.state.nearbyCompanies === null;

    return (
      <Container className="page-container">
        <Typography
          variant="h3"
          sx={{
            paddingTop: "36px",
            paddingBottom: "24px",
            textAlign: "center",
          }}
        >
          {city.name + ", " + city.state}
        </Typography>
        <Box style={{ textAlign: "center" }}>
          <img
            alt=""
            src={city.imageUrl}
            style={{
              overflow: "hidden",
              borderRadius: "16px",
              maxHeight: "300px",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Description"}
            </Typography>
            {this.getDescription()}
          </CardContent>
        </Card>
        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Known For"}
            </Typography>
            <Stack
              gap={1}
              direction="row"
              justifyContent="center"
              flexWrap="wrap"
            >
              {city.knownFor.map((i) => (
                <Chip
                  label={i}
                  color="primary"
                  size="medium"
                  onClick={() =>
                    this.props.navigate(`/cities?knownForFilter=${i}`)
                  }
                />
              ))}
            </Stack>
          </CardContent>
        </Card>

        <StatCard title="Statistics">
          <SimpleStat
            label="Population"
            value={city.population.toLocaleString()}
            icon={PeopleAltIcon}
          />
          <SimpleStat
            label="Pop. Density (per sq mi)"
            value={(+city.populationDensity.toFixed(2)).toLocaleString()}
            icon={ApartmentIcon}
          />
          <SimpleStat
            label="Area (sq mi)"
            value={(+city.area.toFixed(2)).toLocaleString()}
            icon={MapIcon}
          />
          <SimpleStat
            label="Elevation (ft)"
            value={city.elevation.toLocaleString()}
            icon={LandscapeIcon}
          />
          <SimpleStat
            label="Timezone"
            value={city.timezone}
            icon={AccessTimeIcon}
          />
        </StatCard>

        <StatCard title="Ratings">
          <Card elevation={0}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <RadialBarChart
                width={200}
                height={150}
                cx={"50%"}
                cy={140}
                startAngle={170}
                endAngle={10}
                innerRadius={80}
                outerRadius={80}
                barSize={20}
                data={[
                  {
                    uv: city.covidScore,
                    fill: "rgb(85,114,239)",
                  },
                ]}
              >
                <PolarAngleAxis
                  type="number"
                  domain={[0, 75]}
                  dataKey={"uv"}
                  angleAxisId={0}
                  tick={false}
                />
                <RadialBar
                  background
                  dataKey="uv"
                  cornerRadius={30}
                  label={
                    <>
                      <Text
                        x={100}
                        y={25}
                        fill="rgba(0, 0, 0, 0.87)"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={24}
                      >
                        {"COVID-19 Risk"}
                      </Text>
                      <Text
                        x={100}
                        y={110}
                        fill="rgba(0, 0, 0, 0.54)"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={24}
                      >
                        {+parseFloat(city.covidScore).toFixed(2)}
                      </Text>
                      <Text
                        x={19}
                        y={140}
                        fill="rgba(0, 0, 0, 0.87)"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={12}
                      >
                        {0}
                      </Text>
                      <Text
                        x={180}
                        y={140}
                        fill="rgba(0, 0, 0, 0.87)"
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={12}
                      >
                        {75}
                      </Text>
                    </>
                  }
                />
              </RadialBarChart>
            </CardContent>
          </Card>
          <Card elevation={0}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "28px" }} component="legend">
                Safety
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {safetyText[city.safety - 1]}
              </Typography>
              <Rating name="safety" value={city.safety} readOnly size="large" />
            </CardContent>
          </Card>
          <Card elevation={0}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "28px" }} component="legend">
                Cost of Living
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {budgetText[city.budget - 1]}
              </Typography>
              <Rating
                name="budget"
                precision={0.005}
                max={8}
                value={city.budget}
                readOnly
                size="large"
              />
            </CardContent>
          </Card>
          <Card elevation={0}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "28px" }} component="legend">
                Average Rating
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {ratingText[Math.ceil(city.averageRating) - 1]}
              </Typography>
              <Rating
                name="average"
                precision={0.01}
                value={+city.averageRating.toFixed(2)}
                readOnly
                size="large"
              />
            </CardContent>
          </Card>
        </StatCard>

        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Links"}
            </Typography>
            <Stack
              className="socialStack"
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {city.googleEventsUrl !== "none" && (
                <Button
                  variant="outlined"
                  href={city.googleEventsUrl}
                  startIcon={<EventsIcon />}
                  target="_blank"
                >
                  Google Events
                </Button>
              )}
              {city.walkScoreUrl !== "none" && (
                <Button
                  variant="outlined"
                  href={city.walkScoreUrl}
                  target="_blank"
                >
                  Walk Score
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>
        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Map"}
            </Typography>
            <div className="centerText">
              <iframe
                title="map_frame"
                width="768"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                // allowfullscreen
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCNeTLLH83DplVigjOugoPDOadr9c5xu6s&q=${city.name}+${city.state}`}
              />
            </div>
          </CardContent>
        </Card>
        {loadingNearby && <LoadingWidget height="300px" />}
        {showNearbyColleges && (
          <Typography
            variant="h3"
            sx={{
              paddingTop: "36px",
              paddingBottom: "24px",
              textAlign: "center",
            }}
          >
            Colleges in this City
          </Typography>
        )}
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {(this.state.nearbyColleges ?? []).map((college: College) => {
            return (
              <CollegeModelCard
                key={"college" + college.id}
                college={college}
              />
            );
          })}
        </Stack>
        {showNearbyColleges && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button
              variant="outlined"
              href={`/colleges?cityNameFilter=${city.name}&stateFilter=${city.state}`}
            >
              View All Colleges in City
            </Button>
          </Box>
        )}
        {showNearbyCompanies && (
          <Typography
            variant="h3"
            sx={{
              paddingTop: "36px",
              paddingBottom: "24px",
              textAlign: "center",
            }}
          >
            Companies in the City
          </Typography>
        )}
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {(this.state.nearbyCompanies ?? []).map((company: Company) => {
            return (
              <CompanyModelCard
                key={"company" + company.id}
                company={company}
              />
            );
          })}
        </Stack>
        {showNearbyCompanies && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <Button
              variant="outlined"
              href={`/companies?cityNameFilter=${city.name}&stateFilter=${city.state}`}
            >
              View All Companies in City
            </Button>
          </Box>
        )}
      </Container>
    );
  }
}

export default CityInstancePage;
