import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CityModelCard from "./CityModelCard";
import axios, { AxiosResponse } from "axios";
import { Container, Stack, Typography } from "@mui/material";
import { ModelToolbar } from "../toolbar/ModelToolbar";
import LoadingWidget from "../common/LoadingWidget";
import { API_URL } from "../globals";
import {
  CityExactFilters,
  CityRangeFilters,
  CitySortOptions,
} from "./cityFilters";
import CardPagination from "../common/CardPagination";
import City from "./city";

function CityModelPage(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  let [cities, setCities] = useState<City[] | null>(null);
  let [total, setTotal] = useState<number>(0);
  let [page, setPage] = useState<number>(0);
  let [pageSize, setPageSize] = useState<number>(0);
  let [pageCount, setPageCount] = useState<number>(0);
  let [focusFields, setFocusFields] = useState<Set<string>>(new Set<string>());

  useEffect(() => {
    const fetchData = async () => {
      setCities(null);
      setTotal(0);
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/cities?` + searchParams
      );
      let data = response.data["data"];
      setCities(data["cities"]);
      setTotal(data["total"]);
      setPage(data["page"]);
      setPageSize(data["pageSize"]);
      setPageCount(Math.ceil(data["total"] / data["pageSize"]));
    };
    fetchData();
  }, [searchParams]);

  const updateQuery = (param: string, value: string) => {
    let newParams: URLSearchParams = new URLSearchParams(
      searchParams.toString()
    );
    newParams.set(param, value);
    setSearchParams(newParams);
  };

  return (
    <Container
      className="page-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: (cities ?? []).length === 0 ? "100%" : "none",
      }}
    >
      <Typography
        gutterBottom
        className="modelTitle"
        variant="h2"
        sx={{ textAlign: "center" }}
      >
        Cities
      </Typography>
      <div>
        <ModelToolbar
          exactFilters={CityExactFilters}
          rangeFilters={CityRangeFilters}
          sortOptions={CitySortOptions}
          defaultSortOptionIndex={1}
          sortAscending={false}
          onFocusFieldsUpdate={setFocusFields}
        />
      </div>
      {cities === null && <LoadingWidget height="none" grow={true} />}
      {cities !== null && (
        <Stack direction="row" flexWrap="wrap">
          {cities!.map((c) => (
            <CityModelCard
              key={c.id}
              city={c}
              query={searchParams.get("query") ?? undefined}
              showAllKnownFor={focusFields.has("knownFor")}
              showBudget={focusFields.has("budget")}
              showSafety={focusFields.has("safety")}
              showElevation={focusFields.has("elevation")}
              showArea={focusFields.has("area")}
              showPopulationDensity={focusFields.has("populationDensity")}
              showCovidScore={focusFields.has("covidScore")}
              showAverageRating={focusFields.has("averageRating")}
              onKnownForClick={(value) => {
                updateQuery("knownForFilter", value);
              }}
            />
          ))}
        </Stack>
      )}
      <CardPagination
        page={page}
        pageSize={pageSize}
        pageCount={pageCount}
        total={total}
        onPageChange={(page) => updateQuery("page", page.toString())}
        onPageSizeChange={(pageSize) =>
          updateQuery("pageSize", pageSize.toString())
        }
      />
    </Container>
  );
}

export default CityModelPage;
