import { stateOptions } from "../common/states";
import { ExactFilter, RangeFilter, SortOption } from "../toolbar/ModelToolbar";

const CollegeExactFilters: ExactFilter[] = [
  {
    label: "State Filter",
    field: "state",
    options: stateOptions,
  },
  {
    label: "City Filter",
    field: "cityName",
  },
  {
    label: "Level Filter",
    field: "level",
    options: [
      {
        label: "4-year",
        value: "4-year",
      },
      {
        label: "2-year",
        value: "2-year",
      },
    ],
  },
  {
    label: "Ownership Filter",
    field: "typeControl",
    options: [
      {
        label: "Public",
        value: "public",
      },
      {
        label: "Private For-Profit",
        value: "private for-profit",
      },
      {
        label: "Private Non-Profit",
        value: "private non-profit",
      },
    ],
  },
];

const CollegeRangeFilters: RangeFilter[] = [
  {
    label: "Enrollment",
    field: "enrollmentSize",
    min: 1_000,
    max: 80_000,
    nonlinear: true,
    compactNumbers: true,
    marks: [
      {
        label: "<1K",
        value: 1_000,
      },
      {
        label: "4K",
        value: 4_000,
      },
      {
        label: "10K",
        value: 10_000,
      },
      {
        label: "40K",
        value: 40_000,
      },
      {
        label: ">80K",
        value: 80_000,
      },
    ],
  },
  {
    label: "Average SAT",
    field: "satAvg",
    min: 0,
    max: 1600,
    step: 1,
    marks: [
      {
        label: "0",
        value: 0,
      },
      {
        label: "800",
        value: 800,
      },
      {
        label: "1200",
        value: 1200,
      },
      {
        label: "1600",
        value: 1600,
      },
    ],
  },
];

const CollegeSortOptions: SortOption[] = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Enrollment",
    field: "enrollmentSize",
  },
  {
    label: "Admission Rate",
    field: "admissionRate",
  },
  {
    label: "SAT Average",
    field: "satAvg",
  },
  {
    label: "SAT Math Average",
    field: "satMidMath",
  },
  {
    label: "SAT Reading Average",
    field: "satMidRead",
  },
  {
    label: "Average Cost",
    field: "avgCost",
  },
  // {
  //     label: "In-State Tuition (Asc)",
  //     field: "tuitionInState",
  //     ascending: true
  // },
  // {
  //     label: "In-State Tuition (Desc)",
  //     field: "tuitionInState",
  //     ascending: false
  // },
  // {
  //     label: "Out-of-State Tuition (Asc)",
  //     field: "tuitionOutOfState",
  //     ascending: true
  // },
  // {
  //     label: "Out-of-State Tuition (Desc)",
  //     field: "tuitionOutOfState",
  //     ascending: false
  // },
];

export { CollegeExactFilters, CollegeRangeFilters, CollegeSortOptions };
