import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { Box } from "@mui/material";
import LoadingWidget from "../common/LoadingWidget";

type data_type = { [key: string]: any };
// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

function MarineLifeCalorieVisualization(props: any) {
  let [data, setData] = useState<any[]>([]);
  let [data2, setData2] = useState<any[]>([]);
  useEffect(() => {
    const get_data = async () => {
      let marine_life_response: AxiosResponse<any, any> = await axios.get(
        "https://api.seastainable.me/marinelife/all"
      );
      let ingredient_response: AxiosResponse<any, any> = await axios.get(
        "https://api.seastainable.me/ingredients/all"
      );
      let marine_data = marine_life_response.data;
      let ingredient_data = ingredient_response.data;
      let processed_data = process_data(marine_data);
      setData(processed_data);
      let processed_data2 = process_ingredients(ingredient_data);
      setData2(processed_data2);
    };
    get_data();
  }, []);

  let visualization = <LoadingWidget height="300px"></LoadingWidget>;
  if (data2.length > 0) visualization = radar_chart(data, data2);

  return (
    <Box display="flex" justifyContent="center">
      {visualization}
    </Box>
  );
}

const process_ingredients = (ingredients_data: any[]) => {
  const calorie_types = [1, 10, 25, 50, 100, 150, 200];
  var tmp = new Array<number>(calorie_types.length + 1).fill(0);
  var count: number = 0;
  for (const data_string of ingredients_data) {
    const data = JSON.parse(data_string.nutrition);
    var type = calorie_types.length;
    const nutritional_facts = data.nutrients;
    var calorie = NaN;
    for (const nutrients of nutritional_facts) {
      if (nutrients.name === "Calories") {
        calorie = nutrients.amount;
        break;
      }
    }
    if (!isNaN(calorie)) {
      for (let i = calorie_types.length; i >= 0; i--) {
        if (calorie <= calorie_types[i]) {
          type = i;
        }
      }
      count++;
      tmp[type]++;
    }
  }
  var ret: data_type[] = [];
  for (let i = 0; i <= calorie_types.length; i++) {
    if (i === calorie_types.length) {
      ret.push({ name: ">200 calories", value: (100 * tmp[i]) / count });
    } else {
      ret.push({
        name: "<" + calorie_types[i] + " calories",
        value: (100 * tmp[i]) / count,
      });
    }
  }
  console.log(ret);
  return ret;
};
const process_data = (marine_life_data: any[]) => {
  const calorie_types = [1, 10, 25, 50, 100, 150, 200];
  var tmp = new Array<number>(calorie_types.length + 1).fill(0);
  var count: number = 0;
  for (const data of marine_life_data) {
    var type = calorie_types.length;
    const calorie = Number(String(data.calories).split(" ")[0]);
    if (!isNaN(calorie)) {
      for (let i = calorie_types.length; i >= 0; i--) {
        if (calorie <= calorie_types[i]) {
          type = i;
        }
      }
      count++;
      tmp[type]++;
    }
  }
  var ret: data_type[] = [];
  for (let i = 0; i <= calorie_types.length; i++) {
    if (i === calorie_types.length) {
      ret.push({ name: ">200 calories", value: (100 * tmp[i]) / count });
    } else {
      ret.push({
        name: "<" + calorie_types[i] + " calories",
        value: (100 * tmp[i]) / count,
      });
    }
  }
  return ret;
};
const radar_chart = (marine_life_data: any[], ingredient_data: any[]) => {
  var data = [];
  for (let i = 0; i < marine_life_data.length; i++) {
    data.push({
      name: marine_life_data[i].name,
      x: marine_life_data[i].value,
      y: ingredient_data[i].value,
    });
  }
  return (
    <RadarChart
      cx={250}
      cy={200}
      outerRadius={125}
      width={500}
      height={450}
      data={data}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="name" />
      <PolarRadiusAxis angle={30} domain={[0, 100]} />
      <Radar
        name="Percentage of marine life within each caloric threshold"
        dataKey="x"
        fill="*8884d8"
        fillOpacity={0.6}
      />
      <Radar
        name="Percentage of ingredients within each caloric threshold"
        dataKey="y"
        fill="#82ca9d"
        fillOpacity={0.6}
      />
      <Legend />
    </RadarChart>
  );
};
export default MarineLifeCalorieVisualization;
