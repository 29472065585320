import { Container, Stack } from "@mui/material";
import React from "react";
import axios, { AxiosResponse } from "axios";
import LoadingWidget from "../common/LoadingWidget";
import RecipeChart from "./RecipeChart";

interface RecipeInfo {
  dfRating: number;
  dfCost: number;
  gfRating: number;
  gfCost: number;
  veganRating: number;
  veganCost: number;
  vegetRating: number;
  vegetCost: number;
}

class RecipeChartVisualization extends React.Component<
  {},
  { recipeInfo: RecipeInfo | null }
> {
  constructor(props: any) {
    super(props);
    this.state = { recipeInfo: null };
  }

  componentDidMount() {
    this.loadRecipeInfo();
  }

  async loadRecipeInfo() {
    let response: AxiosResponse<any, any> = await axios.get(
      `https://api.seastainable.me/recipes/all`
    );
    let data: any = response.data;

    // dfRating, dfCost, gfRating, gfCost, veganRating, veganCos, vegetRating, vegetCost
    let averages = [0, 0, 0, 0, 0, 0, 0, 0];
    let avgCounts = [0, 0, 0, 0];
    for (let i = 0; i < data.length; i++) {
      let datum = data[i];
      let diet = JSON.parse(datum["diet"]);
      if (diet["dairyFree"]) {
        averages[0] += datum["ratingVal"];
        averages[1] += datum["costVal"];
        avgCounts[0]++;
      }
      if (diet["glutenFree"]) {
        averages[2] += datum["ratingVal"];
        averages[3] += datum["costVal"];
        avgCounts[1]++;
      }
      if (diet["vegan"]) {
        averages[4] += datum["ratingVal"];
        averages[5] += datum["costVal"];
        avgCounts[2]++;
      }
      if (diet["vegetarian"]) {
        averages[6] += datum["ratingVal"];
        averages[7] += datum["costVal"];
        avgCounts[3]++;
      }
    }

    for (let i = 0; i < averages.length; i++) {
      averages[i] /= avgCounts[Math.floor(i / 2)];
      averages[i] = Math.round(averages[i]);
    }

    let info: RecipeInfo = {
      dfRating: averages[0],
      dfCost: averages[1],
      gfRating: averages[2],
      gfCost: averages[3],
      veganRating: averages[4],
      veganCost: averages[5],
      vegetRating: averages[6],
      vegetCost: averages[7],
    };
    this.setState({
      recipeInfo: info,
    });
  }

  render() {
    if (this.state.recipeInfo === null) {
      return <LoadingWidget height="300px" />;
    }

    return (
      <Container>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <RecipeChart
            dfCost={this.state.recipeInfo.dfCost}
            dfRating={this.state.recipeInfo.dfRating}
            gfCost={this.state.recipeInfo.gfCost}
            gfRating={this.state.recipeInfo.gfRating}
            veganCost={this.state.recipeInfo.veganCost}
            veganRating={this.state.recipeInfo.veganRating}
            vegetCost={this.state.recipeInfo.vegetCost}
            vegetRating={this.state.recipeInfo.vegetRating}
          />
        </Stack>
      </Container>
    );
  }
}

export default RecipeChartVisualization;
