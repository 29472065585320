import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ModelToolbar } from "../toolbar/ModelToolbar";
import { useSearchParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { Box } from "@mui/system";
import { API_URL } from "../globals";
import CompanyTableActions from "./CompanyTableActions";
import Highlighter from "react-highlight-words";
import {
  CompanyExactFilters,
  CompanyRangeFilters,
  CompanySortOptions,
} from "./companyFilters";
import Company from "./company";

function CompanyModelPage(props: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  let [companies, setCompanies] = useState<Company[] | null>(null);
  let [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/companies?` + searchParams
      );
      let data = response.data["data"];
      setCompanies(data["companies"]);
      setTotal(data["total"]);
    };

    fetchData();
  }, [searchParams]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    searchParams.set("page", (newPage + 1).toString());
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    searchParams.set("pageSize", event.target.value.toString());
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return (
    <Container
      className="page-container"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        className="modelTitle"
        variant="h2"
        sx={{ textAlign: "center" }}
        gutterBottom
      >
        Companies
      </Typography>
      <div>
        <ModelToolbar
          exactFilters={CompanyExactFilters}
          rangeFilters={CompanyRangeFilters}
          sortOptions={CompanySortOptions}
          defaultSortOptionIndex={2}
          sortAscending={false}
        />
      </div>

      <TableContainer
        component={Paper}
        sx={{
          margin: "8px",
          width: "-webkit-fill-available",
          borderRadius: "16px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: "24px" }}>Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell align="right" sx={{ paddingRight: "24px" }}>
                Employees
              </TableCell>
              <TableCell align="right" sx={{ paddingRight: "24px" }}>
                Annual Revenue
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(companies ?? []).map((company) => (
              <TableRow
                hover
                key={company.id}
                component={"a"}
                href={`/companies/${company.id}`}
                sx={{
                  textDecoration: "none",
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ paddingLeft: "24px" }}
                >
                  <Box
                    sx={{
                      // width: "12px",
                      display: "flex",
                    }}
                  >
                    <img
                      src={
                        "https://storage.googleapis.com/univercity-images/favicons/" +
                        company.domain
                      }
                      style={{
                        height: "20px",
                        width: "20px",
                        // maxWidth: "20px",
                        marginRight: "16px",
                      }}
                      alt="favicon"
                    />
                    <Highlighter
                      highlightClassName="highlighter"
                      searchWords={searchParams.get("query")?.split(" ") ?? []}
                      autoEscape={true}
                      textToHighlight={company.name}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Highlighter
                    highlightClassName="highlighter"
                    searchWords={searchParams.get("query")?.split(" ") ?? []}
                    autoEscape={true}
                    textToHighlight={company.cityName}
                  />
                </TableCell>
                <TableCell>
                  <Highlighter
                    highlightClassName="highlighter"
                    searchWords={searchParams.get("query")?.split(" ") ?? []}
                    autoEscape={true}
                    textToHighlight={company.state}
                  />
                </TableCell>
                <TableCell>
                  <Highlighter
                    highlightClassName="highlighter"
                    searchWords={searchParams.get("query")?.split(" ") ?? []}
                    autoEscape={true}
                    textToHighlight={company.sector ?? ""}
                  />
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: "24px" }}>
                  {company.employees === 0 || company.employees === null
                    ? "N/A"
                    : company.employees.toLocaleString()}
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: "24px" }}>
                  {company.annualRevenue === 0
                    ? "N/A"
                    : "$" + company.annualRevenue.toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          sx={{
            marginRight: "8px",
          }}
          rowsPerPageOptions={[15, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={parseInt(searchParams.get("pageSize") ?? "15", 10)}
          page={parseInt(searchParams.get("page") ?? "1", 10) - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={CompanyTableActions}
        />
      </TableContainer>
    </Container>
  );
}

export default CompanyModelPage;
