import { Slider } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";

interface SliderFilterFieldProps {
  value: number[];
  min: number;
  max: number;
  step?: number;
  field: string;
  label: string;
  marks?: Mark[];
  nonlinear?: boolean;
  autoMark?: boolean;
  compactNumbers?: boolean;
  onChange: (value: number[]) => void;
}

interface Mark {
  value: number;
  label: string;
}

const numberFormatter = Intl.NumberFormat("en", { notation: "compact" });

function SliderFilterField(props: SliderFilterFieldProps) {
  const [value, setValue] = React.useState<number[]>([0, 0]);
  const nonlinear: boolean = props.nonlinear ?? false;

  const calculateValue = (value: number): number => {
    return nonlinear ? Math.E ** value : value;
  };
  const calculateSliderValue = useCallback(
    (value: number): number => {
      return nonlinear ? Math.log(value) : value;
    },
    [nonlinear]
  );

  let autoMarks: Mark[] = [];
  if (props.autoMark === true && props.marks === undefined) {
    for (let i = props.min; i <= props.max; i += props.step ?? 1) {
      autoMarks.push({
        label: i.toString(),
        value: i,
      });
    }
  }

  let scaledMarks = [];
  for (let mark of props.marks ?? autoMarks) {
    let scaledMark: Mark = {
      label: mark.label,
      value: calculateSliderValue(mark.value),
    };
    scaledMarks.push(scaledMark);
  }

  useEffect(() => {
    let min: number = props.min;
    let queryMin: string | null = props.value[0].toString();
    if (queryMin != null && queryMin.length !== 0) {
      min = parseInt(queryMin);
    }
    let max: number = props.max;
    let queryMax: string | null = props.value[1].toString();
    if (queryMax != null && queryMax.length !== 0) {
      max = parseInt(queryMax);
    }
    setValue([calculateSliderValue(min), calculateSliderValue(max)]);
  }, [props.value, calculateSliderValue, props.field, props.max, props.min]);

  const handleChange = (newValue: number[]) => {
    setValue(newValue);
  };

  const getLabel = (value: number): string => {
    if (props.compactNumbers ?? false) {
      return numberFormatter.format(value);
    } else {
      return value.toString();
    }
  };

  const handleCommittedChange = (newValue: number[]) => {
    let min = Math.round(calculateValue(newValue[0]));
    let max = Math.round(calculateValue(newValue[1]));
    props.onChange([min, max]);
    // let newParams = searchParams;
    // newParams.set(props.field + "Min", min.toString());
    // newParams.set(props.field + "Max", max.toString());
    // if (min === props.min) {
    //     newParams.delete(props.field + "Min");
    // }
    // if (max === props.max) {
    //     newParams.delete(props.field + "Max");
    // }
    // setSearchParams(newParams);
  };

  return (
    <Box>
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        {props.label}
      </Typography>
      <Box sx={{ marginTop: "8px", marginLeft: "24px", marginRight: "24px" }}>
        <Slider
          marks={scaledMarks}
          color="primary"
          step={props.step !== undefined ? props.step : nonlinear ? 0.01 : null}
          valueLabelDisplay="auto"
          value={value}
          min={calculateSliderValue(props.min)}
          max={calculateSliderValue(props.max)}
          valueLabelFormat={(value) => getLabel(value)}
          scale={calculateValue}
          onChangeCommitted={(event, newValue) =>
            handleCommittedChange(newValue as number[])
          }
          onChange={(event, newValue) => handleChange(newValue as number[])}
        />
      </Box>
    </Box>
  );
}

export { SliderFilterField, Mark };
