import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ModelCard from "../common/ModelCard";

interface HomeCardProps {
  image: any;
  title: string;
  link: string;
  description: string;
}

class HomeCard extends React.Component<HomeCardProps, {}> {
  render() {
    return (
      <ModelCard
        image={this.props.image}
        href={`${this.props.link}`}
        imageMinHeight="225px"
        imageMaxHeight="225px"
      >
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            {this.props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {this.props.description}
          </Typography>
        </CardContent>
      </ModelCard>
    );
  }
}

export default HomeCard;
