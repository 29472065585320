import React from "react";
import { Box } from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
} from "recharts";

interface RecipeChartProps {
  dfRating: number;
  dfCost: number;
  gfRating: number;
  gfCost: number;
  veganRating: number;
  veganCost: number;
  vegetRating: number;
  vegetCost: number;
}

class RecipeChart extends React.Component<RecipeChartProps, {}> {
  render() {
    const data = [
      {
        name: "Dairy Free",
        Cost: this.props.dfCost,
        Rating: this.props.dfRating,
      },
      {
        name: "Gluten Free",
        Cost: this.props.gfCost,
        Rating: this.props.gfRating,
      },
      {
        name: "Vegan",
        Cost: this.props.veganCost,
        Rating: this.props.veganRating,
      },
      {
        name: "Vegetarian",
        Cost: this.props.vegetCost,
        Rating: this.props.vegetRating,
      },
    ];

    return (
      <Box>
        <ResponsiveContainer width={600} height={300}>
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="Rating" fill="#8884d8" />
            <Bar yAxisId="right" dataKey="Cost" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }
}

export default RecipeChart;
