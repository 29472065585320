import React from "react";
import { Box } from "@mui/material";
import { PieChart, Pie, Text, Cell, Sector } from "recharts";

interface NutritionChartProps {
  avgCal: number;
  avgProtein: number;
  avgCarb: number;
  avgFat: number;
  label: string;
  type: string;
}

interface ChartStyle {
  width: number;
  height: number;
  barFill: string;
  radius: number;
  barSize: number;
  centerX: number | string;
  centerY: number | string;
  valueFontSize: string;
  labelFontSize: string;
}

class NutritionChart extends React.Component<NutritionChartProps, {}> {
  state = {
    activeIndex: -1,
  };

  render() {
    const data = [
      { name: "Carbs", value: this.props.avgCarb },
      { name: "Fat", value: this.props.avgFat },
      { name: "Protein", value: this.props.avgProtein },
    ];
    const COLORS = ["#00C49F", "#FFBB28", "#FF8042"];

    const styleOptions = new Map<string, ChartStyle>([
      [
        "small",
        {
          width: 300,
          height: 150,
          barFill: "rgb(85,114,239)",
          radius: 120,
          barSize: 30,
          centerX: "50%",
          centerY: 140,
          valueFontSize: "32px",
          labelFontSize: "16px",
        },
      ],
    ]);

    const cs: ChartStyle =
      styleOptions.get(this.props.type) ?? styleOptions.get("small")!;

    // const RADIAN = Math.PI / 180;
    let CustomLabel = (props: any) => {
      const cx = props.cx;
      const cy = props.cy;

      // const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5 + 30;
      // const x = cx + radius * Math.cos(-props.midAngle * RADIAN);
      // const y = cy + radius * Math.sin(-props.midAngle * RADIAN);

      return (
        <>
          {this.state.activeIndex === -1 && props.index === 0 && (
            <>
              <Text
                x={cx}
                y={cy - 10}
                fill="rgba(0, 0, 0, 0.87)"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={cs.valueFontSize}
              >
                {props.value1}
              </Text>

              <Text
                x={cx}
                y={cy + 15}
                fill="rgba(0, 0, 0, 0.54)"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize={cs.labelFontSize}
              >
                {props.value2}
              </Text>
            </>
          )}
          {props.index === 0 && (
            <Text
              x={cx}
              y={cy + 100}
              fill="rgba(0, 0, 0, 0.87)"
              textAnchor="middle"
              dominantBaseline="central"
              fontSize={cs.labelFontSize}
            >
              {props.value3}
            </Text>
          )}
        </>
      );
    };

    let onPieEnter = (_: any, index: any) => {
      this.setState({
        activeIndex: index,
      });
    };

    let onPieLeave = (props: any) => {
      this.setState({
        activeIndex: -1,
      });
    };

    const renderActiveShape = (props: any) => {
      const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent /*, value */,
      } = props;

      return (
        <g>
          <Text
            x={cx}
            y={cy - 10}
            fill="rgba(0, 0, 0, 0.87)"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={cs.valueFontSize}
          >
            {Math.round(percent * 100).toLocaleString() + "%"}
          </Text>
          <Text
            x={cx}
            y={cy + 15}
            fill="rgba(0, 0, 0, 0.54)"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={cs.labelFontSize}
          >
            {"Avg " + payload.name}
          </Text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
        </g>
      );
    };

    return (
      <Box>
        <PieChart width={200} height={250}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx={100}
            cy={125}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            label={
              <CustomLabel
                value1={this.props.avgCal}
                value2={"Avg Calories"}
                value3={this.props.label}
              />
            }
            labelLine={false}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </Box>
    );
  }
}

export default NutritionChart;
