import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import MarineLifeCalorieVisualization from "./MarineLifeCalorieVisualization";
import NutritionChartSection from "./NutritionChartVisualization";
import RecipeChartSection from "./RecipeChartSection";

function ProviderVisualizationsPage(props: any) {
  return (
    <Container className="page-container" sx={{ textAlign: "center" }}>
      <Stack justifyContent="center" direction="column" textAlign="center">
        <Typography className="modelTitle" variant="h2" gutterBottom>
          Seastainable Visualizations
        </Typography>
        <Typography
          gutterBottom
          sx={{ marginTop: "16px", marginBottom: "8px" }}
          variant="h4"
        >
          Marine Life and Ingredients by Caloric Threshold
        </Typography>
        <MarineLifeCalorieVisualization />
        <Typography
          gutterBottom
          sx={{ marginTop: "48px", marginBottom: "8px" }}
          variant="h4"
        >
          Calorie and Macronutrient Content per Food Group
        </Typography>
        <NutritionChartSection />
        <Typography
          gutterBottom
          sx={{ marginTop: "48px", marginBottom: "8px" }}
          variant="h4"
        >
          Average Rating and Cost across Dietary Restrictions
        </Typography>
        <RecipeChartSection />
      </Stack>
    </Container>
  );
}
export default ProviderVisualizationsPage;
