import React from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { Twitter, Facebook, LinkedIn } from "@mui/icons-material";
import {
  LocationCity as CityIcon,
  Language as WebsiteIcon,
} from "@mui/icons-material";
import axios, { AxiosResponse } from "axios";
import CollegeModelCard from "../college/CollegeModelCard";
import { Box } from "@mui/system";
import LoadingWidget from "../common/LoadingWidget";
import { AdvancedChart } from "react-tradingview-embed";
import {
  People as EmployeesIcon,
  AttachMoney as RevenueIcon,
  Flag as FoundedIcon,
} from "@mui/icons-material";
import {
  LocationOn as USRankIcon,
  Public as GlobalRankIcon,
} from "@mui/icons-material";
import StatCard from "../common/StatCard";
import SimpleStat from "../common/SimpleStat";
import College from "../college/college";
import Company from "./company";

function CompanyInstancePage() {
  let id: string = useParams().companyId ?? "1";
  let navigate = useNavigate();
  return <CompanyInstancePageCC id={id} navigate={navigate} />;
}

interface CompanyInstancePageProps {
  id: string;
  navigate: NavigateFunction;
}

interface CompanyInstancePageState {
  company: Company | null;
  nearbyColleges: College[];
}

class CompanyInstancePageCC extends React.Component<
  CompanyInstancePageProps,
  CompanyInstancePageState
> {
  constructor(props: any) {
    super(props);
    this.state = { company: null, nearbyColleges: [] };
  }

  componentDidMount() {
    this.loadCompany();
    this.loadNearbyColleges();
  }

  async loadCompany() {
    let response: AxiosResponse<any, any> = await axios.get(
      `https://api.univercity.me/companies/${this.props.id}`
    );
    let company: Company | null = response.data["data"]["company"];
    this.setState({
      company: company,
    });
  }

  async loadNearbyColleges() {
    let response: AxiosResponse<any, any> = await axios.get(
      `https://api.univercity.me/colleges?nearbyCompanyId=${this.props.id}&pageSize=4&required=description,thumbnailUrl`
    );

    let colleges: College[] = response.data["data"]["colleges"];
    this.setState({
      nearbyColleges: colleges,
    });
  }

  render() {
    if (this.state.company == null) return <LoadingWidget height="100%" />;

    let company: Company = this.state.company!;

    if (this.state.nearbyColleges.length === 0)
      return <LoadingWidget height="100%" />;
    let nearbyColleges: College[] = this.state.nearbyColleges;
    const numberFormatter = Intl.NumberFormat("en", { notation: "compact" });
    let revenue: string = "N/A";
    if (company.annualRevenue !== null) {
      revenue = numberFormatter.format(company.annualRevenue);
    }

    let employees: string = "N/A";
    if (company.employees !== null) {
      employees = numberFormatter.format(company.employees);
    }

    return (
      <Container className="page-container">
        <Typography
          variant="h3"
          sx={{
            paddingTop: "36px",
            paddingBottom: "8px",
            textAlign: "center",
          }}
        >
          {company.name}
        </Typography>
        <Card className="card instanceCard" elevation={3}>
          <CardContent>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography gutterBottom variant="h4" component="div">
                  {"Summary"}
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {company.name +
                    " is a " +
                    (company.public ? "public " : "private ") +
                    company.sector?.toLocaleLowerCase() +
                    " company based in "}
                  {company.cityName + ", " + company.state}
                  {"."}
                </Typography>
                <Button
                  variant="outlined"
                  href={`/cities/${company.cityId}`}
                  startIcon={<CityIcon />}
                  sx={{
                    marginTop: "8px",
                    marginRight: "8px",
                  }}
                >
                  City Info
                </Button>
                <Button
                  variant="outlined"
                  href={company.url}
                  target="_blank"
                  startIcon={<WebsiteIcon />}
                  sx={{
                    marginTop: "8px",
                    marginLeft: "8px",
                  }}
                >
                  Website
                </Button>
              </Box>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src={company.logoURL}
                  style={{
                    objectFit: "contain",
                    minWidth: "200px",
                    maxWidth: "200px",
                  }}
                />
              </div>
            </Stack>
          </CardContent>
        </Card>
        <Card className="card instanceCard" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Description"}
            </Typography>
            <Typography variant="body1" color="text.primary">
              {company.description}
            </Typography>
          </CardContent>
        </Card>
        <Card className="card instanceCard" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Industries"}
            </Typography>
            <Stack
              gap={1}
              direction="row"
              justifyContent="center"
              flexWrap="wrap"
            >
              {company.industries.map((i) => (
                <Chip
                  label={i}
                  color="primary"
                  size="medium"
                  onClick={() =>
                    this.props.navigate(`/companies?industriesFilter=${i}`)
                  }
                />
              ))}
            </Stack>
          </CardContent>
        </Card>

        <StatCard title="Company Statistics">
          <SimpleStat
            label="Founded"
            value={(company.founded ?? "N/A").toLocaleString()}
            icon={FoundedIcon}
          />
          <SimpleStat
            label="Annual Revenue"
            value={"$" + revenue}
            icon={RevenueIcon}
          />
          <SimpleStat
            label="Employees"
            value={employees}
            icon={EmployeesIcon}
          />
        </StatCard>

        <StatCard title="Website Statistics">
          <SimpleStat
            label="US Rank"
            value={(company.alexaUsRank ?? "N/A").toLocaleString()}
            icon={USRankIcon}
          />
          <SimpleStat
            label="Global Rank"
            value={(company.alexaGlobalRank ?? "N/A").toLocaleString()}
            icon={GlobalRankIcon}
          />
        </StatCard>

        {company.ticker !== null && (
          <Card className="card instanceCard">
            <CardContent>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                align="center"
              >
                {"Stock"}
              </Typography>
              <AdvancedChart
                widgetProps={{
                  symbol: company.ticker,
                  theme: "light",
                  allow_symbol_change: false,
                  hide_top_toolbar: true,
                  hide_side_toolbar: true,
                }}
              />
            </CardContent>
          </Card>
        )}
        <Card className="card instanceCard" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Social Media"}
            </Typography>
            <Stack
              className="socialStack"
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {company.twitterHandle != null && (
                <Button
                  variant="outlined"
                  startIcon={<Twitter />}
                  href={"https://twitter.com/" + company.twitterHandle}
                  target="_blank"
                >
                  Twitter
                </Button>
              )}
              {company.facebookHandle != null && (
                <Button
                  variant="outlined"
                  startIcon={<Facebook />}
                  href={"https://facebook.com/" + company.facebookHandle}
                  target="_blank"
                >
                  Facebook
                </Button>
              )}
              {company.linkedinHandle != null && (
                <Button
                  variant="outlined"
                  startIcon={<LinkedIn />}
                  href={"https://linkedin.com/" + company.linkedinHandle}
                  target="_blank"
                >
                  LinkedIn
                </Button>
              )}
            </Stack>
            {company.twitterHandle != null && (
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName={company.twitterHandle!}
                options={{ height: 600 }}
              />
            )}
          </CardContent>
        </Card>

        <Typography
          variant="h3"
          sx={{
            paddingTop: "36px",
            paddingBottom: "24px",
            textAlign: "center",
          }}
        >
          Nearby Colleges
        </Typography>
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {nearbyColleges.map((college: College) => {
            return (
              <CollegeModelCard
                key={"college" + college.id}
                college={college}
              />
            );
          })}
        </Stack>
        {/* <Button 
					variant='outlined' 
					href={`/colleges?cityNameFilter=${company.cityName}&stateFilter=${company.state}`}
				>
					View All
				</Button> */}
      </Container>
    );
  }
}

export default CompanyInstancePage;
