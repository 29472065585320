import { Box, IconButton } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

interface CompanyTableActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function CompanyTableActions(props: CompanyTableActionsProps) {
  const handleFirstPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onPageChange(event, 0);
  };

  const handleLastPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onPageChange(
      event,
      Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)
    );
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onPageChange(event, props.page + 1);
  };

  const handlePreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onPageChange(event, props.page - 1);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "nowrap", marginLeft: "24px" }}>
      <IconButton onClick={handleFirstPage} disabled={props.page === 0}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handlePreviousPage} disabled={props.page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextPage}
        disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPage}
        disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default CompanyTableActions;
