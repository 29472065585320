const knownFor = [
  "Charming",
  "Foodie",
  "Nightlife",
  "Architecture",
  "History",
  "Museums",
  "Performing Arts",
  "Music",
  "Hipster",
  "Hippie",
  "Posh",
  "Family Friendly",
  "LGBT Scene",
  "Diversity",
  "Beach Town",
  "College Town",
  "Ski Town",
  "Outdoorsy",
  "Wineries",
  "Shopping",
];

export default knownFor;
