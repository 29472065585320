import React from "react";
import cole_img from "../../assets/cole.png";
import matthew_img from "../../assets/matthew.png";
import jonathan_img from "../../assets/jonathan.png";
import kristina_img from "../../assets/kristina.png";
import axios, { AxiosResponse } from "axios";
import "./aboutStyle.css";
import { TOOLS, APIS } from "./infoCards";
import {
  CardContent,
  Typography,
  Divider,
  Stack,
  Box,
  Container,
  Button,
  Tooltip,
  Skeleton,
} from "@mui/material";
import ModelCard from "../common/ModelCard";
import {
  Commit as CommitIcon,
  FormatListBulleted as IssueIcon,
  TaskAlt as TestIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";
import StatCard from "../common/StatCard";
import SimpleStat from "../common/SimpleStat";

interface AboutState {
  people: Person[];
  totalCommits: number;
  totalIssues: number;
  totalTests: number;
  loading: boolean;
}

class About extends React.Component<{}, AboutState> {
  constructor(props: any) {
    super(props);
    this.state = {
      people: PEOPLE,
      totalCommits: 0,
      totalIssues: 0,
      totalTests: 81,
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    // if (this.state.totalCommits === 0) {
    //     return (
    //         <LoadingWidget grow={true} />
    //     );
    // }
    console.log(this.state.loading);
    return (
      <Container className="page-container" sx={{ textAlign: "center" }}>
        <Typography sx={{ margin: "16px" }} variant="h3">
          About UniverCity
        </Typography>
        <Typography sx={{ margin: "8px" }} variant="body1">
          UniverCity is a website that links together information about college
          campuses, the cities they are in, and the companies that are around
          the cities. This would be useful for prospective college students who
          want to find information about colleges that they are considering.
        </Typography>
        <Typography sx={{ margin: "16px", marginTop: "36px" }} variant="h3">
          Team Members
        </Typography>
        <div>
          {this.state.people.map((p) => (
            <ModelCard
              key={p.username}
              fitImage={false}
              height="580px"
              width="270px"
              imageHeight="270px"
              image={p.image}
            >
              <CardContent>
                <Typography variant="h5" component="div">
                  {p.name}
                </Typography>
                <Typography
                  sx={{ mb: 0 }}
                  variant="subtitle1"
                  color="text.secondary"
                >
                  {p.role}
                </Typography>
                <Typography
                  sx={{ mb: 1.5 }}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  {p.leader}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {p.bio}
                </Typography>
              </CardContent>
              <Box style={{ flexGrow: 2 }}></Box>
              <Divider sx={{ width: "300px" }}></Divider>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1.5}
                sx={{
                  alignSelf: "center",
                  padding: "16px",
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  alignSelf="center"
                  width="60px"
                >
                  <CommitIcon fontSize="small" />
                  <Typography variant="body2" margin="0px">
                    Commits
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    {this.state.loading ? (
                      <Skeleton width={"36px"} />
                    ) : (
                      p.commits
                    )}
                  </Typography>
                </Stack>
                <Tooltip title={"Total issues assigned"} followCursor={true}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    alignSelf="center"
                    width="60px"
                  >
                    <IssueIcon fontSize="small" />
                    <Typography variant="body2" margin="2px">
                      Issues
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                      {this.state.loading ? (
                        <Skeleton width={"36px"} />
                      ) : (
                        p.issues
                      )}
                    </Typography>
                  </Stack>
                </Tooltip>
                <Stack
                  direction="column"
                  alignItems="center"
                  alignSelf="center"
                  width="60px"
                >
                  <TestIcon fontSize="small" />
                  <Typography variant="body2" margin="2px">
                    Tests
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    {this.state.loading ? <Skeleton width={"36px"} /> : p.tests}
                  </Typography>
                </Stack>
              </Stack>
            </ModelCard>
          ))}
        </div>
        <Typography sx={{ margin: "16px", marginTop: "36px" }} variant="h3">
          Repository Data
        </Typography>
        <StatCard elevation={1}>
          <SimpleStat
            label="Total Commits"
            value={this.state.totalCommits.toString()}
            icon={CommitIcon}
            loading={this.state.loading}
          />
          <SimpleStat
            label="Total Issue"
            value={this.state.totalIssues.toString()}
            icon={IssueIcon}
            loading={this.state.loading}
          />
          <SimpleStat
            label="Total Tests"
            value={this.state.totalTests.toString()}
            icon={TestIcon}
            loading={this.state.loading}
          />
        </StatCard>
        <Typography sx={{ margin: "16px", marginTop: "36px" }} variant="h3">
          Tools
        </Typography>
        <div>
          {TOOLS.map((t) => (
            <ModelCard
              key={t.name}
              width="250px"
              height="290px"
              imageHeight="150px"
              href={t.link}
              target="_blank"
              image={t.image ?? t.imageURL}
              fitImage={true}
              center={true}
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t.description}
                </Typography>
              </CardContent>
            </ModelCard>
          ))}
        </div>
        <Typography sx={{ margin: "16px", marginTop: "36px" }} variant="h3">
          APIs
        </Typography>
        <div>
          {APIS.map((a) => (
            <ModelCard
              key={a.name}
              width="250px"
              height="290px"
              imageHeight="150px"
              href={a.link}
              target="_blank"
              image={a.image ?? a.imageURL}
              fitImage={true}
              center={true}
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {a.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {a.description}
                </Typography>
              </CardContent>
            </ModelCard>
          ))}
        </div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ padding: "36px" }}
        >
          <Button
            variant="outlined"
            href="https://gitlab.com/coleweinman/swe-college-project/"
            target="_blank"
          >
            Repository
            <LaunchIcon sx={{ marginLeft: "8px" }} />
          </Button>
          <Button
            variant="outlined"
            href="https://documenter.getpostman.com/view/19780536/UVyoWHgx"
            target="_blank"
          >
            API Documentation
            <LaunchIcon sx={{ marginLeft: "8px" }} />
          </Button>
        </Stack>
      </Container>
    );
  }

  async getData() {
    let totalCommits: number = 0;
    let developerTotalCommits: number = 0;
    let commitMap: Map<string, number> = new Map();
    let newPeople: Person[] = this.state.people;
    let pageSize = 500;
    let page = 1;
    while (totalCommits % pageSize === 0) {
      let response: AxiosResponse<any, any> = await axios.get(
        `https://gitlab.com/api/v4/projects/33874129/repository/commits?per_page=${pageSize}&page=${page++}`
      );
      if (response.data.length === 0) {
        break;
      }
      for (let commit of response.data) {
        let email = commit.author_email;
        commitMap.set(email, (commitMap.get(email) ?? 0) + 1);
        totalCommits++;
      }
    }
    for (let person of newPeople) {
      for (let email of person.emails) {
        person.commits += commitMap.get(email) ?? 0;
      }
      developerTotalCommits += person.commits;
    }
    console.log(commitMap);
    let totalIssues: number = 0;
    let assignedTotalIssues: number = 0;
    page = 1;
    let issuesMap: Map<string, number> = new Map();
    while (totalIssues % pageSize === 0) {
      let issuesResponse: AxiosResponse<any, any> = await axios.get(
        `https://gitlab.com/api/v4/projects/33874129/issues?per_page=${pageSize}&page=${page++}`
      );
      console.log(issuesResponse.data.length);
      if (issuesResponse.data.length === 0) {
        break;
      }
      for (let issue of issuesResponse.data) {
        if (issue.assignee != null) {
          let username = issue.assignee.username;
          issuesMap.set(username, (issuesMap.get(username) ?? 0) + 1);
        }
        totalIssues++;
      }
    }
    for (let person of newPeople) {
      person.issues = issuesMap.get(person.username) ?? 0;
      assignedTotalIssues += person.issues;
    }
    this.setState({
      people: newPeople,
      totalCommits: developerTotalCommits,
      totalIssues: assignedTotalIssues,
      loading: false,
    });
  }
}

interface Person {
  name: string;
  role: string;
  leader: string;
  bio: string;
  image: any;
  emails: string[];
  username: string;
  commits: number;
  issues: number;
  tests: number;
}

const PEOPLE: Person[] = [
  {
    name: "Cole Weinman",
    role: "Full Stack Developer",
    leader: "Phase 1 Lead",
    bio: `I'm a second year computer science major at UT Austin. I'm from Richardson, Texas and I enjoy music, swimming and playing with my dog.`,
    image: cole_img,
    emails: ["coleweinman@gmail.com"],
    username: "coleweinman",
    commits: 0,
    issues: 0,
    tests: 14,
  },
  {
    name: "Matthew Kozlowski",
    role: "Full Stack Developer",
    leader: "Phase 2 Lead",
    bio: `I'm studying Computer Science at UT Austin. I'm from The Woodlands, Texas and I like to read, watch movies, and go rock climbing.`,
    image: matthew_img,
    emails: ["mkozlowski225@gmail.com", "mkozlowski@utexas.edu"],
    username: "matkozlowski",
    commits: 0,
    issues: 0,
    tests: 33,
  },
  {
    name: "Kristina Zhou",
    role: "Full Stack Developer",
    leader: "Phase 4 Lead",
    bio: `I'm a second year at UT Austin studying computer science and math. I enjoy reading and music in my free time.`,
    image: kristina_img,
    emails: ["zhou.kristina@utexas.edu"],
    username: "zhou.kristina",
    commits: 0,
    issues: 0,
    tests: 15,
  },
  {
    name: "Jonathan Zhao",
    role: "Backend Developer",
    leader: "Phase 3 Lead",
    bio: `My name is Jonathan Zhao. I'm a junior and graduating in May 2023. I love typing. My current keyboard is the Ducky miya pro.`,
    image: jonathan_img,
    emails: ["jzhaotxus@gmail.com", "jonathanzhao@utexas.edu"],
    username: "jonathanzhao1",
    commits: 0,
    issues: 0,
    tests: 19,
  },
];

export default About;
