import mui_img from "../../assets/mui.png";
import postman_img from "../../assets/postman.png";
import react_img from "../../assets/react.png";
import roadgoat_img from "../../assets/roadgoat.png";
import gitlab_img from "../../assets/gitlab.png";

var TOOLS: Info[] = [
  {
    name: "GitLab",
    description: "DevOps platform",
    link: "https://gitlab.com/coleweinman/swe-college-project/",
    image: gitlab_img,
  },
  {
    name: "Material UI",
    description: "React Material Design UI library",
    link: "https://mui.com/",
    image: mui_img,
  },
  {
    name: "Postman",
    description: "API designing Tool",
    link: "https://www.postman.com/",
    image: postman_img,
  },
  {
    name: "React",
    description: "JavaScript web framework for frontend",
    link: "https://reactjs.org/",
    image: react_img,
  },
  {
    name: "Namecheap",
    description: "Domain name registrar",
    link: "https://www.namecheap.com/",
    imageURL:
      "https://saasreviewhq.com/wp-content/uploads/2021/04/namecheap.svg",
  },
  {
    name: "Docker",
    description: "Containerization tool",
    link: "https://www.docker.com/",
    imageURL:
      "https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png",
  },
  {
    name: "AWS Amplify",
    description: "Frontend hosting platform",
    link: "https://aws.amazon.com/amplify/",
    imageURL:
      "https://images.prismic.io/ionicframeworkcom/d3576c2287f6fd34c80c31bccf81cd3149dafc08_aws-amplify.png",
  },
  {
    name: "Amazon RDS",
    description: "Relational database hosting service",
    link: "https://aws.amazon.com/rds/",
    imageURL: "https://media.graphcms.com/cv9vca0YRemw8ARpICex",
  },
  {
    name: "PostgreSQL",
    description: "Object-relational databse system",
    link: "https://www.postgresql.org/",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg",
  },
  {
    name: "Flask",
    description: "Python micro web framework for backend",
    link: "https://flask.palletsprojects.com/",
    imageURL:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQl7BEwqp9RfFRZt_guSqMHxWC2zFqrI8-rQ&usqp=CAU",
  },
  {
    name: "Cloud Run",
    description: "Managed compute platform for backend",
    link: "https://flask.palletsprojects.com/",
    imageURL: "https://miro.medium.com/max/562/1*gYGvyOHloJ2JKO8MX3fYAw.png",
  },
  {
    name: "SQLAlchemy",
    description: "Python SQL toolkit and object relational mapper",
    link: "https://www.sqlalchemy.org/",
    imageURL: "https://www.sqlalchemy.org/img/sqla_logo.png",
  },
  {
    name: "Flask SQLAlchemy",
    description: "Flask extension with SQLAlchemy support",
    link: "https://www.sqlalchemy.org/",
    imageURL:
      "https://flask-sqlalchemy.palletsprojects.com/en/2.x/_static/flask-sqlalchemy-logo.png",
  },
  {
    name: "Selenium",
    description: "Web application testing framework",
    link: "https://www.sqlalchemy.org/",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/d/d5/Selenium_Logo.png",
  },
  {
    name: "Jest",
    description: "JavaScript testing framework",
    link: "https://jestjs.io/",
    imageURL:
      "https://seeklogo.com/images/J/jest-logo-F9901EBBF7-seeklogo.com.png",
  },
  {
    name: "Black",
    description: "Python code formatter",
    link: "https://black.readthedocs.io/en/stable/",
    imageURL: "https://black.readthedocs.io/en/stable/_static/logo2.png",
  },
  {
    name: "ReCharts",
    description: "Visualization library",
    link: "https://recharts.org/en-US/",
    imageURL: "https://miro.medium.com/max/1400/1*Fwiw0oM1J_HIQZFWcaBPYw.png",
  },
  {
    name: "Discord",
    description: "Communication platform",
    link: "https://discord.com",
    imageURL:
      "https://logos-world.net/wp-content/uploads/2020/12/Discord-Logo.png",
  },
  {
    name: "Cloud Storage",
    description: "Photo storage",
    link: "https://cloud.google.com/storage/",
    imageURL:
      "https://download.logo.wine/logo/Google_Storage/Google_Storage-Logo.wine.png",
  },
  {
    name: "Prettier",
    description: "Frontend formatter",
    link: "https://prettier.io/",
    imageURL: "https://prettier.io/icon.png",
  },
];

var APIS: Info[] = [
  {
    name: "College Scorecard API",
    description: "API for college data",
    link: "https://collegescorecard.ed.gov/data/documentation/",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Seal_of_the_United_States_Department_of_Education.svg/1200px-Seal_of_the_United_States_Department_of_Education.svg.png",
  },
  {
    name: "Icon Horse API",
    description: "API for company logos",
    link: "https://icon.horse/",
    imageURL: "https://icon.horse/icon/icon.horse",
  },
  {
    name: "RoadGoat",
    description: "API for city data",
    link: "https://www.roadgoat.com/business/cities-api",
    image: roadgoat_img,
  },
  {
    name: "Big Picture API",
    description: "API for company data",
    link: "https://bigpicture.io/docs/api/#introduction",
    imageURL: "https://bigpicture.io/img/bp-logo-B-teal.svg",
  },
  {
    name: "MediaWiki API",
    description: "API for city and college descriptions",
    link: "https://www.mediawiki.org/wiki/API:Main_page",
    imageURL:
      "https://upload.wikimedia.org/wikipedia/commons/8/8c/MediaWiki-2020-large-icon.svg",
  },
  {
    name: "Bing Image Search API",
    description: "API for city and college images",
    link: "https://www.microsoft.com/en-us/bing/apis/bing-image-search-api",
    imageURL:
      "https://cdn.vox-cdn.com/thumbor/wBRCdEaZtpAd2bJBlOhtRC6euVk=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/21937385/binglogo.jpg",
  },
];

interface Info {
  name: string;
  description: string;
  link: string;
  image?: any;
  imageURL?: string;
}

export { TOOLS, APIS };
