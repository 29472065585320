const sectors: string[] = [
  "Basic Material",
  "Consumer Goods",
  "Consumer Services",
  "Financials",
  "Health Care",
  "Industrials",
  "Oil & Gas",
  "Technology",
  "Telecommunications",
  "Utilities",
  "Insurance",
];

export default sectors;
