import React, { useEffect, useState } from "react";
import { Stack, TextField, MenuItem, Box } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../globals";
import LoadingWidget from "../common/LoadingWidget";
import {
  Legend,
  ResponsiveContainer,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { stateOptions } from "../common/states";

function StateRadarChartVisualization(props: any) {
  let [currentState, setCurrentState] = React.useState<string>("AL");
  let [data, setData] = React.useState<any[]>([]);
  let [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      let temp: any[] = [];
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/cities?stateFilter=${currentState}&pageSize=2000`
      );

      let avgSafety = 0;
      let avgCOL = 0;
      let avgPop = 0;
      let avgCovid = 0;
      let avgArea = 0;
      let cities: any[] = response.data["data"]["cities"];
      for (let city of cities) {
        avgSafety = avgSafety + city["safety"];
        avgCOL = avgCOL + city["budget"];
        avgPop = avgPop + city["population"];
        avgCovid = avgCovid + city["covidScore"];
        avgArea = avgArea + city["area"];
      }

      avgSafety = ((1.0 * avgSafety) / cities.length) * 2;
      avgCOL = ((1.0 * avgCOL) / cities.length / 8) * 10;
      avgPop = ((1.0 * avgPop) / cities.length / 600000) * 10;
      avgCovid = ((1.0 * avgCovid) / cities.length / 30) * 10;
      avgArea = ((1.0 * avgArea) / cities.length / 250) * 10;

      temp.push({ category: "Safety", v: avgSafety, fullMark: 10 });
      temp.push({ category: "Cost of Living", v: avgCOL, fullMark: 10 });
      temp.push({ category: "Population", v: avgPop, fullMark: 10 });
      temp.push({ category: "COVID Risk", v: avgCovid, fullMark: 10 });
      temp.push({ category: "Area (sq mi)", v: avgArea, fullMark: 10 });

      setData(temp);
      setLoading(false);
    };

    fetchData();
  }, [currentState]);

  if (loading) {
    return <LoadingWidget height="300px" />;
  }

  return (
    <Box marginTop="16px">
      <TextField
        id="filter-field"
        select
        label="State"
        value={currentState}
        onChange={(event) => setCurrentState(event.target.value)}
        InputProps={{
          sx: {
            borderRadius: "8px",
            backgroundColor: "grey",
            flexGrow: 1,
            minWidth: "150px",
            display: "flex",
            textAlign: "start",
          },
        }}
      >
        {stateOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Stack justifyContent="center">
        <ResponsiveContainer width="100%" height={300}>
          <RadarChart outerRadius={90} width={730} height={250} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="category" />
            <PolarRadiusAxis angle={18} domain={[0, 10]} />
            <Radar
              name="Average"
              dataKey="v"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      </Stack>
    </Box>
  );
}

export default StateRadarChartVisualization;
