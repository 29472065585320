import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Chip, Stack } from "@mui/material";
import "./collegeStyle.css";
import ModelCard from "../common/ModelCard";
import Highlighter from "react-highlight-words";
import College from "./college";

interface CollegeModelCardProps {
  college: College;
  query?: string;
  showEnrollment?: boolean;
  showAdmissionRate?: boolean;
  showAvgSAT?: boolean;
  showSatMidMath?: boolean;
  showSatMidRead?: boolean;
  showAvgCost?: boolean;
  onTypeControlClick?: (typeControl: string) => void;
  onLevelClick?: (level: string) => void;
}

function CollegeModelCard(props: CollegeModelCardProps) {
  let college = props.college;

  return (
    <ModelCard
      height={college.distance == null ? "325px" : "350px"}
      image={college.pictureUrl}
      imageMinHeight="100px"
      href={`/colleges/${college.id}`}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          <Highlighter
            highlightClassName="highlighter"
            searchWords={props.query?.split(" ") ?? []}
            autoEscape={true}
            textToHighlight={college.name}
          />
        </Typography>
        {college.distance !== undefined && (
          <Typography variant="body2" color="text.secondary">
            {college.distance + " miles away"}
          </Typography>
        )}
        <Typography variant="subtitle1" color="text.secondary">
          <Highlighter
            highlightClassName="highlighter"
            searchWords={props.query?.split(" ") ?? []}
            autoEscape={true}
            textToHighlight={college.cityName + ", " + college.state}
          />
        </Typography>
        {props.showEnrollment && (
          <Typography variant="body2" color="text.secondary">
            Enrollment:{" "}
            {college.enrollmentSize === 0
              ? "Unknown"
              : college.enrollmentSize.toLocaleString("en-US")}
          </Typography>
        )}
        {props.showAdmissionRate && (
          <Typography variant="body2" color="text.secondary">
            Admission Rate: {(college.admissionRate * 100).toFixed(2)}%
          </Typography>
        )}
        {props.showAvgSAT && (
          <Typography variant="body2" color="text.secondary">
            Average SAT: {college.satAvg.toString()}
          </Typography>
        )}
        {props.showSatMidMath && (
          <Typography variant="body2" color="text.secondary">
            SAT Math Average: {college.satMidMath.toLocaleString()}
          </Typography>
        )}
        {props.showSatMidRead && (
          <Typography variant="body2" color="text.secondary">
            SAT Read Average: {college.satMidRead.toLocaleString()}
          </Typography>
        )}
        {props.showAvgCost && (
          <Typography variant="body2" color="text.secondary">
            Average Cost: ${college.avgCost.toLocaleString()}
          </Typography>
        )}
        <Stack direction="row" spacing={1} sx={{ marginTop: "8px" }}>
          <Chip
            label={college.typeControl === "public" ? "Public" : "Private"}
            size="small"
            color={college.typeControl === "public" ? "success" : "warning"}
            onMouseDown={(event) => event.stopPropagation()}
            onClick={
              props.onTypeControlClick === null
                ? undefined
                : (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    props.onTypeControlClick!(college.typeControl);
                  }
            }
          />
          <Chip
            label={college.level}
            variant="outlined"
            size="small"
            onMouseDown={(event) => event.stopPropagation()}
            onClick={
              props.onLevelClick === null
                ? undefined
                : (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    props.onLevelClick!(college.level);
                  }
            }
          />
        </Stack>
      </CardContent>
    </ModelCard>
  );
}

export default CollegeModelCard;
