import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

var LINKS = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Search",
    link: "/search",
  },
  {
    name: "Colleges",
    link: "/colleges",
  },
  {
    name: "Cities",
    link: "/cities",
  },
  {
    name: "Companies",
    link: "/companies",
  },
  {
    name: "Visualizations",
    link: "/visualizations",
  },
  {
    name: "Provider Visualizations",
    link: "/providervisualizations",
  },
  {
    name: "About",
    link: "/about",
  },
];

function NavigationBar(props: any) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  let navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (link: string) => {
    navigate(link);
    setAnchorElNav(null);
  };

  return (
    // <Nav variant="pills" defaultActiveKey="/home">
    //     <Nav.Item>
    //         <Nav.Link href="/home">Home</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //         <Nav.Link href="/cities" eventKey="cities">Cities</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //         <Nav.Link href="/colleges">Colleges</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //         <Nav.Link href="/companies">Companies</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item>
    //         <Nav.Link eventKey="disabled" disabled>
    //         Disabled
    //         </Nav.Link>
    //     </Nav.Item>
    // </Nav>
    <AppBar position="static" elevation={3}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => handleCloseNavMenu("/home")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
            }}
          >
            UniverCity
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {LINKS.map((l) => (
                <MenuItem
                  key={l.name}
                  onClick={() => handleCloseNavMenu(l.link)}
                >
                  <Typography textAlign="center">{l.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => handleCloseNavMenu("/home")}
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              cursor: "pointer",
            }}
          >
            UniverCity
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {LINKS.map((l) => (
              <Button
                key={l.name}
                onClick={() => handleCloseNavMenu(l.link)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {l.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    // <Navbar className="navbar" variant="dark">
    //     <Container className="page-container">
    //         <Navbar.Brand href="/home">UniverCity</Navbar.Brand>
    //         <Nav className="me-auto">
    //             {LINKS.map((l) => <NavLink name={l.name} link={l.link}></NavLink>)}
    //         </Nav>
    //     </Container>
    // </Navbar>
  );
}

export default NavigationBar;
