import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./cityStyle.css";
import ModelCard from "../common/ModelCard";
import { Chip, Stack } from "@mui/material";
import Highlighter from "react-highlight-words";
import City from "./city";

interface CityModelCardProps {
  city: City;
  query?: string;
  showAllKnownFor?: boolean;
  showSafety?: boolean;
  showBudget?: boolean;
  showElevation?: boolean;
  showArea?: boolean;
  showPopulationDensity?: boolean;
  showCovidScore?: boolean;
  showAverageRating?: boolean;
  onKnownForClick?: (knownFor: string) => void;
}

function CityModelCard(props: CityModelCardProps) {
  let city = props.city;
  let kf = [];
  for (let i = 0; i < Math.min(4, city.knownFor.length); i++) {
    kf.push(city.knownFor[i]);
  }

  return (
    <ModelCard
      height="350px"
      imageMinHeight="125px"
      image={city.imageUrl}
      href={`/cities/${city.id}`}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          <Highlighter
            highlightClassName="highlighter"
            searchWords={props.query?.split(" ") ?? []}
            autoEscape={true}
            textToHighlight={city.name}
          />
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          <Highlighter
            highlightClassName="highlighter"
            searchWords={props.query?.split(" ") ?? []}
            autoEscape={true}
            textToHighlight={city.state}
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Population:{" "}
          {city.population === 0
            ? "Unknown"
            : city.population.toLocaleString("en-US")}
        </Typography>
        {props.showBudget && (
          <Typography variant="body2" color="text.secondary">
            Budget Score: {city.budget}/8
          </Typography>
        )}
        {props.showSafety && (
          <Typography variant="body2" color="text.secondary">
            Safety Score: {city.safety}/5
          </Typography>
        )}
        {props.showElevation && (
          <Typography variant="body2" color="text.secondary">
            Elevation: {city.elevation.toLocaleString()} ft
          </Typography>
        )}
        {props.showArea && (
          <Typography variant="body2" color="text.secondary">
            Area: {Math.round(city.area).toLocaleString()} sq miles
          </Typography>
        )}
        {props.showPopulationDensity && (
          <Typography variant="body2" color="text.secondary">
            Population Density:{" "}
            {Math.round(city.populationDensity).toLocaleString()} people per sq
            mile
          </Typography>
        )}
        {props.showCovidScore && (
          <Typography variant="body2" color="text.secondary">
            COVID-19 Risk: {city.covidScore}/100
          </Typography>
        )}
        {props.showAverageRating && (
          <Typography variant="body2" color="text.secondary">
            Average Rating: {city.averageRating.toFixed(1)}/5.0
          </Typography>
        )}
        <Stack
          direction="row"
          flexWrap="wrap"
          gap="8px"
          sx={{ marginTop: "8px" }}
        >
          {(props.showAllKnownFor ? props.city.knownFor : kf).map((label) => (
            <Chip
              key={city.id + label}
              onMouseDown={(event) => event.stopPropagation()}
              onClick={
                props.onKnownForClick === null
                  ? undefined
                  : (event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      props.onKnownForClick!(label);
                    }
              }
              label={
                <Highlighter
                  highlightClassName="highlighter-chip"
                  searchWords={props.query?.split(" ") ?? []}
                  autoEscape={true}
                  textToHighlight={label}
                />
              }
              size="small"
              color="primary"
            />
          ))}
        </Stack>
      </CardContent>
    </ModelCard>
  );
}

export default CityModelCard;
