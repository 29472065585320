import { Clear, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

function HomeSearchBar(props: any) {
  const [value, setValue] = React.useState<string>("");
  let navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", opacity: 0.9 }}>
      <Paper className="card" style={{ flexBasis: "800px", flexShrink: 1 }}>
        <TextField
          // fullWidth={true}
          id="search-field"
          // label="Search"
          value={value}
          placeholder="Search Sitewide"
          onKeyDown={(event) => {
            if (event.key === "Enter" && value.length > 0) {
              navigate(`/search?query=${value}`);
            }
          }}
          onChange={(event) => setValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment:
              value.length === 0 ? null : (
                <InputAdornment position="end">
                  <IconButton onClick={() => setValue("")}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            sx: {
              borderRadius: "16px",
              backgroundColor: "grey",
            },
          }}
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
          // notched={false}
          variant="outlined"
        />
      </Paper>
    </Box>
  );
}

export default HomeSearchBar;
