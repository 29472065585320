import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import {
  LocationCity as CityIcon,
  Language as WebsiteIcon,
} from "@mui/icons-material";
import CompanyModelCard from "../company/CompanyModelCard";
import LoadingWidget from "../common/LoadingWidget";
import SatScoreChart from "./satScoreChart";
import {
  Person as EnrollmentIcon,
  AccountBalance as DegreeIcon,
  TaskAlt as AcceptIcon,
  Public as TuitionOutStateIcon,
  Home as TuitionInStateIcon,
  AttachMoney as AvgCostIcon,
} from "@mui/icons-material";
import StatCard from "../common/StatCard";
import SimpleStat from "../common/SimpleStat";
import College from "./college";
import Company from "../company/company";

function CollegeInstancePage() {
  let id: string = useParams().collegeId ?? "1";
  return <CollegeInstancePageCC id={id} />;
}

interface CollegeInstancePageProps {
  id: string;
}

interface CollegeInstancePageState {
  college: College | null;
  nearbyCompanies: Company[];
}

class CollegeInstancePageCC extends React.Component<
  CollegeInstancePageProps,
  CollegeInstancePageState
> {
  constructor(props: any) {
    super(props);
    this.state = { college: null, nearbyCompanies: [] };
  }

  componentDidMount() {
    this.loadCollege();
    this.loadNearbyCompanies();
  }

  async loadCollege() {
    let response: AxiosResponse<any, any> = await axios.get(
      `https://api.univercity.me/colleges/${this.props.id}`
    );
    let college: College | null = response.data["data"]["college"];
    this.setState({
      college: college,
    });
  }

  async loadNearbyCompanies() {
    let response: AxiosResponse<any, any> = await axios.get(
      `https://api.univercity.me/companies?nearbyCollegeId=${this.props.id}&pageSize=4`
    );

    let companies: Company[] = response.data["data"]["companies"];
    this.setState({
      nearbyCompanies: companies,
    });
  }

  render() {
    if (this.state.college == null) return <LoadingWidget height="100%" />;

    let college: College = this.state.college!;

    if (this.state.nearbyCompanies.length === 0)
      return <LoadingWidget height="100%" />;
    let nearbyCompanies: Company[] = this.state.nearbyCompanies;

    return (
      <Container className="page-container">
        <Typography
          variant="h3"
          sx={{
            paddingTop: "36px",
            paddingBottom: "24px",
            textAlign: "center",
          }}
        >
          {college.name}
        </Typography>

        <Box style={{ textAlign: "center" }}>
          <img
            alt=""
            src={college.pictureUrl}
            style={{
              overflow: "hidden",
              borderRadius: "16px",
              maxHeight: "300px",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </Box>

        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Summary"}
            </Typography>
            <Typography color="text.primary" fontSize="20px" align="center">
              {college.name +
                " is a " +
                college.typeControl +
                " college located in "}
              {college.cityName + ", " + college.state}
              {"."}
            </Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
              // spacing={8}
            >
              <Button
                variant="outlined"
                href={`/cities/${college.cityId}`}
                startIcon={<CityIcon />}
                sx={{
                  marginTop: "8px",
                  marginRight: "8px",
                }}
              >
                City Info
              </Button>
              <Button
                variant="outlined"
                href={"https://" + college.homeUrl}
                target="_blank"
                startIcon={<WebsiteIcon />}
                sx={{
                  marginTop: "8px",
                  marginLeft: "8px",
                }}
              >
                Website
              </Button>
            </Stack>
          </CardContent>
        </Card>

        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Description"}
            </Typography>
            <Typography variant="body1" color="text.primary">
              {college.description}
            </Typography>
          </CardContent>
        </Card>

        <StatCard title="Enrollment Statistics">
          <SimpleStat
            label="Acceptance Rate"
            value={(college.admissionRate * 100).toFixed() + "%"}
            icon={AcceptIcon}
          />
          <SimpleStat
            label="Primary Degree"
            value={
              college.typeDegree.charAt(0).toUpperCase() +
              college.typeDegree.slice(1) +
              "'s"
            }
            icon={DegreeIcon}
          />
          <SimpleStat
            label="Enrolled Students"
            value={"$" + college.enrollmentSize.toLocaleString()}
            icon={EnrollmentIcon}
          />
        </StatCard>

        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Average SAT Scores"}
            </Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="space-evenly"
              alignItems="flex-end"
            >
              <SatScoreChart
                label="Math"
                score={college.satMidMath}
                minScore={200}
                maxScore={800}
                type="small"
              />
              <SatScoreChart
                label="Total"
                score={college.satAvg}
                minScore={400}
                maxScore={1600}
                type="big"
              />
              <SatScoreChart
                label="Reading"
                score={college.satMidRead}
                minScore={200}
                maxScore={800}
                type="small"
              />
            </Stack>
          </CardContent>
        </Card>

        <StatCard title="Financial Statistics">
          <SimpleStat
            label="In-State Tuition"
            value={"$" + college.tuitionInState.toLocaleString()}
            icon={TuitionInStateIcon}
          />
          <SimpleStat
            label="Average Cost"
            value={"$" + college.avgCost.toLocaleString()}
            icon={AvgCostIcon}
          />
          <SimpleStat
            label="Out-of-State Tuition"
            value={"$" + college.tuitionOutOfState.toLocaleString()}
            icon={TuitionOutStateIcon}
          />
        </StatCard>

        <Card className="instanceCard card" elevation={3}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              {"Map"}
            </Typography>
            <div className="centerText">
              <iframe
                title="college_map_frame"
                width="900"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                src={
                  "https://www.google.com/maps/embed/v1/place?key=AIzaSyCNeTLLH83DplVigjOugoPDOadr9c5xu6s&q=" +
                  encodeURIComponent(college.name) +
                  "+" +
                  college.state
                }
              ></iframe>
            </div>
          </CardContent>
        </Card>

        <Typography
          variant="h3"
          sx={{
            paddingTop: "36px",
            paddingBottom: "24px",
            textAlign: "center",
          }}
        >
          Nearby Companies
        </Typography>
        <Stack direction="row" flexWrap="wrap" justifyContent="center">
          {nearbyCompanies.map((company: Company) => {
            return <CompanyModelCard key={company.id} company={company} />;
          })}
        </Stack>
      </Container>
    );
  }
}

export default CollegeInstancePage;
