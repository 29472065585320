import { Container, Stack, Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import City from "../city/city";
import CityModelCard from "../city/CityModelCard";
import College from "../college/college";
import CollegeModelCard from "../college/CollegeModelCard";
import LoadingWidget from "../common/LoadingWidget";
import { stateOptions } from "../common/states";
import Company from "../company/company";
import CompanyModelCard from "../company/CompanyModelCard";
import { API_URL } from "../globals";
import { ExactFilter, ModelToolbar } from "../toolbar/ModelToolbar";
import ViewAllBar from "./ViewAllBar";

function SearchPage(props: any) {
  const [searchParams] = useSearchParams();
  const [colleges, setColleges] = React.useState<College[] | null>(null);
  const [cities, setCities] = React.useState<City[] | null>(null);
  const [companies, setCompanies] = React.useState<Company[] | null>(null);
  const [totalColleges, setTotalColleges] = React.useState<number>(0);
  const [totalCities, setTotalCities] = React.useState<number>(0);
  const [totalCompanies, setTotalCompanies] = React.useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchColleges = async () => {
      setColleges(null);
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/colleges?` +
          searchParams.toString() +
          "&pageSize=6&required=description,thumbnailUrl"
      );
      let data = response.data["data"];
      setColleges(data["colleges"]);
      setTotalColleges(data["total"]);
    };

    const fetchCities = async () => {
      setCities(null);
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/cities?` + searchParams.toString() + "&pageSize=6"
      );
      let data = response.data["data"];
      setCities(data["cities"]);
      setTotalCities(data["total"]);
    };

    const fetchCompanies = async () => {
      setCompanies(null);
      let response: AxiosResponse<any, any> = await axios.get(
        `${API_URL}/companies?` + searchParams.toString() + "&pageSize=6"
      );
      let data = response.data["data"];
      setCompanies(data["companies"]);
      setTotalCompanies(data["total"]);
    };
    fetchColleges();
    fetchCities();
    fetchCompanies();
  }, [searchParams]);

  const onViewAll = (link: string) => {
    navigate(link + "?" + searchParams);
  };

  const exactFilters: ExactFilter[] = [
    {
      label: "State",
      field: "state",
      options: stateOptions,
    },
  ];

  return (
    <Container
      className="page-container"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        gutterBottom
        className="modelTitle"
        variant="h2"
        sx={{ textAlign: "center" }}
      >
        Search
      </Typography>
      <ModelToolbar
        exactFilters={exactFilters}
        rangeFilters={[]}
        sortOptions={[]}
        sortAscending={true}
      />
      <Typography
        variant="h3"
        sx={{ padding: "24px 8px 8px 8px", textAlign: "center" }}
      >
        Colleges
      </Typography>
      {colleges === null && <LoadingWidget height="632px" />}
      {colleges !== null && (
        <Stack direction="row" flexWrap="wrap">
          {colleges!.map((c) => (
            <CollegeModelCard
              key={c.id}
              college={c}
              query={searchParams.get("query") ?? undefined}
            />
          ))}
        </Stack>
      )}
      <ViewAllBar
        link="/colleges"
        total={totalColleges}
        onViewAll={onViewAll}
      />
      <Typography
        variant="h3"
        sx={{ padding: "24px 8px 8px 8px", textAlign: "center" }}
      >
        Cities
      </Typography>
      {cities === null && <LoadingWidget height="632px" />}
      {cities !== null && (
        <Stack direction="row" flexWrap="wrap">
          {cities!.map((c) => (
            <CityModelCard
              key={c.id}
              city={c}
              query={searchParams.get("query") ?? undefined}
            />
          ))}
        </Stack>
      )}
      <ViewAllBar link="/cities" total={totalCities} onViewAll={onViewAll} />
      <Typography
        variant="h3"
        sx={{ padding: "24px 8px 8px 8px", textAlign: "center" }}
      >
        Companies
      </Typography>
      {companies === null && <LoadingWidget height="632px" />}
      {companies !== null && (
        <Stack direction="row" flexWrap="wrap">
          {companies!.map((c) => (
            <CompanyModelCard
              key={c.id}
              company={c}
              query={searchParams.get("query") ?? undefined}
            />
          ))}
        </Stack>
      )}
      <ViewAllBar
        link="/companies"
        total={totalCompanies}
        onViewAll={onViewAll}
      />
    </Container>
  );
}

export default SearchPage;
