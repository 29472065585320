import { Stack, Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LoadingWidget from "../common/LoadingWidget";
import { industries } from "../company/industries";
import { API_URL } from "../globals";

function IndustryBarChartVisualization(props: any) {
  let [data, setData] = useState<any[]>([]);
  let [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data.length === 0) {
      setLoading(true);

      const fetchData = async () => {
        let temp: any[] = [];
        let response: AxiosResponse<any, any> = await axios.get(
          `${API_URL}/companies?pageSize=2000`
        );
        let map: Map<string, number> = new Map<string, number>();
        for (let company of response.data["data"]["companies"]) {
          for (let industry of company.industries) {
            map.set(industry, (map.get(industry) ?? 0) + 1);
          }
        }
        for (let key of map.keys()) {
          if ((map.get(key) ?? 0) > 8)
            temp.push({ name: key, Frequency: map.get(key) });
        }
        setData(temp);
        setLoading(false);
      };

      fetchData();
    }
  }, [data]);

  if (loading) {
    return <LoadingWidget height="300px" />;
  }

  return (
    <Stack justifyContent="center">
      <Typography sx={{ margin: "8px" }} variant="body1">
        Click on an industry to see all companies in that industry.
      </Typography>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={(e) => {
            let index = e["activeTooltipIndex"]!;
            props.navigate(`/companies?industriesFilter=${industries[index]}`);
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false}>
            <Label
              value="Industry"
              position="insideBottom"
              style={{ textAnchor: "middle" }}
            />
          </XAxis>
          <YAxis>
            <Label
              angle={-90}
              value="Number of Companies"
              position="insideLeft"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip payload={data} />
          <Bar dataKey="Frequency" fill="#0000FF" />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
}

export default IndustryBarChartVisualization;
