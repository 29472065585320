import colleges_img from "../../assets/colleges.jpeg";
import cities_img from "../../assets/cities.jpeg";
import companies_img from "../../assets/companies.jpg";
import "./home.css";
import React from "react";
import HomeCard from "./HomeCard";
import { Container, Stack, Typography, Box } from "@mui/material";
import HomeSearchBar from "./HomeSearchBar";

function HomePage(props: any) {
  return (
    <Container className="home">
      <div className="splash">
        <Typography
          color="white"
          variant="h1"
          component="div"
          className="centerText"
        >
          UniverCity
        </Typography>

        <Typography
          color="white"
          variant="h5"
          component="div"
          className="centerText"
        >
          Get connected with information about <br />
          colleges, cities, and companies across the United States
        </Typography>
        <Box sx={{ marginTop: "64px" }}>
          <HomeSearchBar />
        </Box>
      </div>

      <Typography
        gutterBottom
        variant="h2"
        component="div"
        className="centerText modelTitle"
      >
        Model Pages
      </Typography>
      <Stack direction="row" justifyContent="center" flexWrap="wrap">
        <HomeCard
          image={colleges_img}
          title="Colleges"
          link="/colleges"
          description="View top colleges in the US. See average SAT scores, enrollment and nearby companies."
        />

        <HomeCard
          image={cities_img}
          title="Cities"
          link="/cities"
          description="View top cities in the US. See maps, population and nearby colleges and companies."
        />

        <HomeCard
          image={companies_img}
          title="Companies"
          link="/companies"
          description="View top companies in the US. See revenue, social media and nearby colleges."
        />
      </Stack>
      <Box display="flex" justifyContent="center" margin="36px">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xSeQNNRqsOI?controls=0"
          title="YouTube video player"
          // frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          // allowfullscreen
        />
      </Box>
    </Container>
  );
}

export default HomePage;
