export const industries = [
  "Software",
  "Transportation",
  "Retail",
  "Logistics",
  "Electrical Distribution",
  "Energy",
  "Marketing",
  "Enterprise Software",
  "Hardware",
  "E-Commerce",
  "FinTech",
  "Consulting",
  "Health Care",
  "Insurance",
  "Food and Beverage",
  "Hospitality",
  "Finance",
  "Financial Services",
  "Telecommunications",
  "Internet",
  "Information Technology",
  "Construction",
  "Oil and Gas",
  "Medical",
  "Medical Device",
  "Agriculture",
  "Manufacturing",
  "Fashion",
  "Natural Resources",
  "Automotive",
  "Food Processing",
  "Pharmaceutical",
  "ISP",
  "Renewable Energy",
  "Banking",
  "Service Industry",
  "Biotechnology",
  "Electronics",
  "Supply Chain Management",
  "Real Estate",
  "Consumer Goods",
  "Industrial",
  "Chemical",
];
