import { Typography, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CollegeSATVisualization from "./CollegeSATVisualization";
import IndustryBarChartVisualization from "./IndustryBarChartVisualization";
import StateRadarChartVisualization from "./StateRadarChartVisualization";

function VisualizationPage(props: any) {
  return (
    <Container className="page-container" sx={{ textAlign: "center" }}>
      <Stack justifyContent="center" direction="column" textAlign="center">
        <Typography className="modelTitle" variant="h2" gutterBottom>
          Visualizations
        </Typography>
        <Typography
          gutterBottom
          sx={{ marginTop: "16px", marginBottom: "8px" }}
          variant="h4"
        >
          Number of Companies in Each Industry
        </Typography>
        <IndustryBarChartVisualization navigate={useNavigate()} />
        <Typography
          gutterBottom
          sx={{ marginTop: "48px", marginBottom: "8px" }}
          variant="h4"
        >
          Average City Statistics by State
        </Typography>
        <StateRadarChartVisualization />
        <Typography
          gutterBottom
          sx={{ marginTop: "48px", marginBottom: "8px" }}
          variant="h4"
        >
          Admission Rate vs Average SAT Score
        </Typography>
        <CollegeSATVisualization />
      </Stack>
    </Container>
  );
}

export default VisualizationPage;
